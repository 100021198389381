import { Dialog, Transition } from '@headlessui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { Fragment, useRef } from 'react';
import Button from '../../../../../../components/Button';
import DatepickerForm from '../../../../../../components/FormComponents/DatepickerForm';
import InputForm from '../../../../../../components/FormComponents/InputForm';
import { getFieldErrorsInReport } from '../../../../../../helpers/handleApiErrorResponse';
import { Report } from '../../../../../../infra/Report';
import { CreateContract } from '../../../../contracts/CreateContract';
import { Contract } from '../../../../models/User';

type ConfirmProps = { contract: Contract; isEdit: boolean };

interface ConfigModalProps {
  contractToEdit?: Contract;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (confirmProps: ConfirmProps) => void;
}

const NewContractModal = ({ contractToEdit, isOpen, onClose, onConfirm }: ConfigModalProps) => {
  const formRef = useRef<FormHandles>(null);

  const handleAddNewContract = () => {
    try {
      const mainFormData = formRef.current?.getData();

      const contract = {
        ...mainFormData,
        id: contractToEdit?.id
      } as Contract;

      const resultContract = new CreateContract(contract);
      resultContract.validate();
      if (!resultContract.isValid()) {
        throw resultContract.reports;
      }

      const isEditContract = !!contractToEdit;

      onConfirm({
        contract: contract,
        isEdit: isEditContract
      });
      onClose();
      clearForm();
    } catch (error) {
      handleErrors(error);
    }
  };

  const handleErrors = (resultError: unknown) => {
    const fieldsErrors = getFieldErrorsInReport(resultError as Report[]);
    formRef.current?.setErrors(fieldsErrors);
  };

  const handleCancel = () => {
    onClose();
    clearForm();
  };

  const clearForm = () => {
    formRef.current?.reset();
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={onClose}>
        <div className="min-h-screen px-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="flex overflow-y-auto overflow-x-hidden fixed top-4 z-50 justify-center items-center h-full md:h-full inset-0">
              <div className="relative px-4 w-full max-w-2xl h-auto mx-3">
                <Form
                  ref={formRef}
                  onSubmit={handleAddNewContract}
                  initialData={contractToEdit}
                  className="flex justify-center"
                >
                  <div className="relative bg-white rounded-lg shadow w-full sm:w-3/5 ">
                    <div className="flex justify-between items-start p-6 rounded-t border-b">
                      <span className="text-xl font-semibold text-gray-title lg:text-2xl">
                        Novo contrato
                      </span>
                    </div>
                    <div className="p-6 space-y-3">
                      <InputForm
                        type="text"
                        name="registration"
                        label="Matrícula"
                        placeholder="Digite uma matrícula"
                      />
                      <DatepickerForm name="admissionDate" label="Data de admissão" />
                      <DatepickerForm name="terminationDate" label="Data de rescisão" />
                    </div>
                    <div className="flex items-center justify-end p-6 space-x-3 rounded-b border-t border-gray-200">
                      <Button variant="default" type="button" onClick={handleCancel}>
                        Cancelar
                      </Button>
                      <Button variant="primary" type="button" onClick={handleAddNewContract}>
                        Salvar
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default NewContractModal;
