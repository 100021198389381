import React, { useEffect, useState } from 'react';
import Search from '../../../../../../components/Search/Search';
import debounceEvent from '../../../../../_shared/helpers/debounce';
import { ListEmployeesDto } from '../../../../dtos/ListEmployeeDto';

type Props = {
  onChange: (filterActive: ListEmployeesDto) => void;
  onClick: () => void;
};

const FilterEmployees: React.FC<Props> = ({ onChange, onClick }) => {
  const [filterValue, setFilterValue] = useState<ListEmployeesDto>({});

  const handleChangeSearchValue = async (searchValue: string) => {
    setFilterValue((old) => ({ ...old, page: 1, search: searchValue }));
  };

  useEffect(() => {
    onChange(filterValue);
  }, [filterValue]);

  return (
    <div className="flex flex-row flex-wrap items-center w-full bg-gray-200 p-2 rounded gap-2">
      <div className="flex flex-row items-center grow bg-white rounded px-2">
        <Search
          onChange={debounceEvent(handleChangeSearchValue, 1000)}
          initValue={filterValue.search}
          placeholder="Nome, CPF ou Email ..."
        />
      </div>
      <div
        className="bg-teal-500 text-white transition duration-300 hover:bg-teal-600 font-medium rounded text-base px-4 py-1 text-center block cursor-pointer w-full sm:w-auto"
        onClick={onClick}
      >
        Filtros
      </div>
    </div>
  );
};

export default FilterEmployees;
