import { OptionSelect } from '../../../components/FormComponents/SelectForm';
import { BusinessGroup } from '../../businessGroup/models/BusinessGroup';

export const flattenBusinessGroup = (businessGroups: BusinessGroup[]): OptionSelect[] => {
  const flattenedOptions: OptionSelect[] = [];
  const flatten = (businessGroup: BusinessGroup) => {
    flattenedOptions.push({
      value: businessGroup.id,
      label: businessGroup.name
    });
  };
  businessGroups.forEach((businessGroup) => {
    flatten(businessGroup);
  });

  return flattenedOptions;
};
