import { ReactComponent as CollaboratorsPhoto } from '../../assets/collaborators.svg';

const Home = () => {
  return (
    <div className="flex flex-col w-full h-full sm:p-20">
      <div className="flex flex-col gap-20 w-full h-full text-center items-center justify-center">
        <span className="sm:text-3xl text-2xl">Bem vindo a área do colaborador!</span>
        <CollaboratorsPhoto className="lg:w-[560px] sm:w-96 w-80 h-auto mx-auto" />
      </div>
    </div>
  );
};

export default Home;
