import { DownloadIcon, EyeIcon } from '@heroicons/react/solid';
import Button from '../../../../../components/Button';
import ListCard from '../../../../../components/ListCard';
import Table from '../../../../../components/Table';
import { ReportIncome } from '../../../models/ReportIncome';

interface Props {
  reportIncomes?: ReportIncome[];
  onClickView: (reportIncomeId: number, isMobile?: boolean) => void;
  onClickDownload: (reportIncome: ReportIncome) => void;
}

const UserReportIncomeTable = ({ reportIncomes, onClickView, onClickDownload }: Props) => {
  return (
    <>
      <div className="sm:flex hidden">
        <Table
          columns={[
            { columnName: 'Ano de Referência', key: 'fileReportIncome.referenceYear' },
            {
              columnName: '',
              key: '',
              component: (value, itemActive) => (
                <div className="flex justify-end space-x-2">
                  <EyeIcon
                    onClick={() => onClickView(itemActive.id)}
                    className="w-5 hover:text-gray-800 transition-all duration-300 cursor-pointer"
                  />
                  <DownloadIcon
                    onClick={() => onClickDownload(itemActive)}
                    className="w-5 hover:text-gray-800 transition-all duration-300 cursor-pointer"
                  />
                </div>
              )
            }
          ]}
          values={reportIncomes}
        />
      </div>
      <div className="flex sm:hidden">
        <ListCard
          itemsLabel={[
            { label: 'Ano de Referência', key: 'fileReportIncome.referenceYear' },
            {
              label: '',
              key: '',
              component: (value, itemActive) => (
                <div className="flex flex-col gap-2 sm:hidden w-full mt-[-16px]">
                  <Button styles="w-full" onClick={() => onClickView(itemActive.id, true)}>
                    Visualizar
                  </Button>
                  <Button
                    styles="w-full"
                    variant="primary"
                    onClick={() => {
                      onClickDownload(itemActive);
                    }}
                  >
                    Download
                  </Button>
                </div>
              )
            }
          ]}
          values={reportIncomes}
        />
      </div>
    </>
  );
};

export default UserReportIncomeTable;
