import { useEffect, useState } from 'react';
import zxcvbn from 'zxcvbn';
import PasswordAlert from './PasswordAlert/PasswordAlert';
import PasswordProgressBar from './PasswordProgressBar/PasswordProgressBar';

type Props = {
  inputPasswordValue: string;
};

const PasswordStrength = ({ inputPasswordValue }: Props) => {
  const [passwordScore, setPasswordScore] = useState(0);
  const [passwordValue, setPasswordValue] = useState('');

  const handleChangePassword = () => {
    if (!inputPasswordValue) {
      setPasswordValue('');
      setPasswordScore(0);
      return;
    }
    setPasswordValue(inputPasswordValue);
    const evaluation = zxcvbn(inputPasswordValue);
    setPasswordScore(evaluation.score + 1);
  };

  useEffect(() => {
    handleChangePassword();
  }, [inputPasswordValue]);

  return (
    <div className="flex flex-row w-full items-start">
      <PasswordProgressBar passwordScore={passwordScore} />
      <PasswordAlert passwordValue={passwordValue} />
    </div>
  );
};

export default PasswordStrength;
