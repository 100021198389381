import api from '../../_shared/services/api';
import { Result, ResultLogin } from '../../_shared/types/api.types';
import { LoginCredentials } from '../contexts/AuthProvider';
import { FirstAccessDto } from '../dtos/FirstAccessDto';
import { ForgotPasswordDto } from '../dtos/ForgotPasswordDto';
import { NewPasswordDto } from '../dtos/NewPasswordDto';
import { RedefinePasswordInputDto, RedefinePasswordOutputDto } from '../dtos/RedefinePasswordDto';

class AuthService {
  public async firstAccess(firstAccessDto: FirstAccessDto): Promise<Result<undefined>> {
    const response = await api.post('/auth/first-access', firstAccessDto);
    return response.data;
  }

  public async forgotPassword(forgotPasswordDto: ForgotPasswordDto): Promise<Result<any>> {
    const response = await api.post('/auth/forgot-password', forgotPasswordDto);
    return response.data;
  }

  public async newPassword(newPasswordDto: NewPasswordDto): Promise<Result<undefined>> {
    const response = await api.post('/auth/new-password', newPasswordDto);
    return response.data;
  }

  public async redefinePassword(
    redefinePasswordDto: RedefinePasswordInputDto
  ): Promise<Result<RedefinePasswordOutputDto>> {
    const response = await api.post('/auth/redefine-password', redefinePasswordDto);
    return response.data;
  }

  public signIn = async (login: LoginCredentials): Promise<Result<ResultLogin>> => {
    const response = await api.post('/auth/login', login);
    return response.data;
  };
}

export default new AuthService();
