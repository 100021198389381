import { PlusIcon } from '@heroicons/react/solid';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import Button from '../../../../components/Button';
import CheckboxForm from '../../../../components/FormComponents/ChekboxForm';
import DatepickerForm from '../../../../components/FormComponents/DatepickerForm';
import InputForm from '../../../../components/FormComponents/InputForm';
import InputMaskForm from '../../../../components/FormComponents/InputMaskForm';
import RadioForm from '../../../../components/FormComponents/RadioForm';
import SelectForm, { OptionSelect } from '../../../../components/FormComponents/SelectForm';
import ConfirmModal from '../../../../components/ModalComponents/ConfirmModal';
import TitleCard from '../../../../components/TitleCard';
import { convertDateToString, convertToDate } from '../../../../helpers/convertToDate';
import {
  getErrorMessage,
  getErrorMessagesByName,
  getFieldErrors,
  manageApiErrorResponse
} from '../../../../helpers/handleApiErrorResponse';
import Routes from '../../../_shared/constants/Routes.enum';
import { flattenBusinessGroup } from '../../../_shared/helpers/FlattenBusinessGroup';
import businessGroupService from '../../../businessGroup/service/businessGroup.service';
import { CreateUserDto } from '../../dtos/CreateUserDto';
import { UpdateUserDto } from '../../dtos/UpdateUserDto';
import { Contract, ProtectedUserClone } from '../../models/User';
import employeeService from '../../service/employee.service';
import ContractCard from './components/ContractCard';
import NewContractModal from './components/NewContractModal';

interface RadioOption {
  id: string;
  value: string;
  label: string;
}

const radioOptions: RadioOption[] = [
  { id: 'masculine', value: 'masculine', label: 'Masculino' },
  { id: 'feminine', value: 'feminine', label: 'Feminino' },
  { id: 'other', value: 'other', label: 'Outro' }
];

type ParamsProps = { id: string };

const CreateEmployee = () => {
  const formRef = useRef<FormHandles>(null);
  const [openNewContractModal, setOpenNewContractModal] = useState(false);
  const [contracts, setContracts] = useState<Contract[]>([]);
  const RESET_INDEX = -1;
  const [selectedContractIndex, setSelectedContractIndex] = useState<number>(RESET_INDEX);
  const [contractError, setContractError] = useState<string>('');
  const history = useHistory();
  const [initialDataValue, setInitialDataValue] = useState<ProtectedUserClone>();
  const { id } = useParams<ParamsProps>();
  const [openModalRemoveContract, setOpenModalRemoveContract] = useState(false);
  const [optionsBusiness, setOptionsBusiness] = useState<OptionSelect[]>([]);

  const createOrUpdateEmployee = async () => {
    const mainFormData = formRef.current?.getData();

    const dto = {
      ...mainFormData,
      birthDate: mainFormData && convertToDate(mainFormData.birthDate),
      contracts: convertContractDates(contracts)
    };

    if (id) {
      const updateUserDto = {
        ...dto
      } as UpdateUserDto;

      try {
        const result = await employeeService.update(id, updateUserDto);
        toast.success(result.message);
        history.push(Routes.LIST_EMPLOYEES);
      } catch (resultError) {
        handleErrors(resultError);
      }
    } else {
      const createUserDto = {
        ...dto
      } as CreateUserDto;
      try {
        const result = await employeeService.create(createUserDto);
        toast.success(result.message);
        history.push(Routes.LIST_EMPLOYEES);
      } catch (resultError) {
        handleErrors(resultError);
      }
    }
  };

  const getOptionsBusiness = async () => {
    const response = await businessGroupService.getBusinessGroup();
    const { results: businessGroups } = response;
    const flattenedOptions = flattenBusinessGroup(businessGroups || []);
    setOptionsBusiness(flattenedOptions);
  };

  const convertContractDates = (contracts: Contract[]) => {
    const convertedContracts = [];
    for (const contract of contracts) {
      const convertContract = {
        id: contract.id,
        registration: contract.registration,
        admissionDate: convertToDate(contract.admissionDate),
        terminationDate: convertToDate(contract.terminationDate)
      } as Contract;
      convertedContracts.push(convertContract);
    }
    return convertedContracts;
  };

  const handleErrors = (resultError: unknown) => {
    const fieldsErrors = getFieldErrors(resultError);
    formRef.current?.setErrors(fieldsErrors);
    const resultErrorResponse = manageApiErrorResponse(resultError);
    const error = getErrorMessage(resultErrorResponse);
    toast.error(error);

    const messageErrors = getErrorMessagesByName(resultError, 'contracts');
    setContractError(messageErrors[0]);
  };

  const handleNewContract = ({ contract, isEdit }: { contract: Contract; isEdit: boolean }) => {
    if (isEdit) {
      const copyContractsList = [...contracts];
      copyContractsList[selectedContractIndex] = contract;
      setContracts(() => copyContractsList);
    } else {
      setContracts((oldList) => {
        const newContractsList = oldList.concat(contract);
        return newContractsList;
      });
    }
  };

  const getSelectedContract = () => {
    return contracts[selectedContractIndex];
  };

  const handleClickNewContract = () => {
    setOpenNewContractModal(true);
    setContractError('');
  };

  const handleCloseNewContract = () => {
    setOpenNewContractModal(false);
    setSelectedContractIndex(RESET_INDEX);
  };

  const handleClickEditContract = (index: number) => {
    setOpenNewContractModal(true);
    setSelectedContractIndex(index);
  };

  const handleClickRemoveContract = (index: number) => {
    setOpenModalRemoveContract(true);
    setSelectedContractIndex(index);
  };

  const handleRemoveContract = () => {
    setContracts((oldList) => {
      const copyContractList = [...oldList];
      copyContractList.splice(selectedContractIndex, 1);
      return copyContractList;
    });
    setOpenModalRemoveContract(false);
    setSelectedContractIndex(RESET_INDEX);
  };

  const loadEmployee = async () => {
    try {
      const result = await employeeService.getEmployee(id);
      const employee: ProtectedUserClone = {
        ...result.data,
        birthDate: convertDateToString(new Date(result.data.birthDate))
      };
      formRef.current?.setData(employee);
      setInitialDataValue(employee);
      formRef.current?.setFieldValue('businessGroupId', {
        value: employee.businessGroup.id,
        label: employee.businessGroup.name
      });
      const contractsMapped =
        employee &&
        employee?.contracts.map(
          (contract) =>
            ({
              id: contract.id,
              registration: contract.registration,
              admissionDate: convertDateToString(new Date(contract.admissionDate)),
              terminationDate:
                contract.terminationDate && convertDateToString(new Date(contract.terminationDate)),
              isActive: contract.isActive
            } as Contract)
        );
      if (contractsMapped) setContracts(contractsMapped);
    } catch (error) {}
  };

  useEffect(() => {
    getOptionsBusiness();
  }, [optionsBusiness]);

  useEffect(() => {
    if (id) loadEmployee();
  }, []);

  return (
    <div className="flex flex-col">
      <div className="flex flex-wrap justify-between">
        <TitleCard text="Novo funcionário" />
        <div className="flex items-center px-6 py-5 gap-3">
          <Button variant="default" type="button" onClick={() => history.goBack()}>
            Voltar
          </Button>
          <Button variant="primary" type="button" onClick={createOrUpdateEmployee}>
            Salvar alterações
          </Button>
        </div>
      </div>
      <Form ref={formRef} onSubmit={createOrUpdateEmployee} initialData={initialDataValue}>
        <div className="flex flex-col p-4 gap-7">
          <div className="border-dashed border border-gray-300">
            <div className="flex flex-col p-6 w-full mx-auto">
              <span className="text-lg font-bold text-gray-600 mb-4">Dados pessoais</span>
              <div className="flex flex-col md:flex-row justify-between md:gap-10 lg:gap-0">
                <div className="flex flex-col w-full md:w-1/2 lg:mr-20 xl:mr-36 2xl:mr-60 gap-4">
                  <InputForm type="text" name="name" label="Nome*" placeholder="Digite um nome" />
                  <DatepickerForm name="birthDate" label="Data de nascimento*" />
                </div>
                <div className="flex flex-col w-full md:w-1/2 lg:mr-20 xl:mr-36 2xl:mr-60 gap-4">
                  <InputMaskForm
                    name="cpf"
                    maskChar={null}
                    inputMask="999.999.999-99"
                    label="CPF*"
                  />
                  <RadioForm name="genre" label="Gênero*" options={radioOptions} />
                </div>
              </div>
            </div>
          </div>
          <div className="border-dashed border border-gray-300">
            <div className="flex flex-col p-6 w-full mx-auto gap-4">
              <span className="text-lg font-bold text-gray-600 mb-4">Contratos</span>
              <div className="flex flex-row gap-5">
                {contracts?.length > 0 ? (
                  contracts.map((contract, index) => (
                    <ContractCard
                      contract={contract}
                      key={index}
                      onClickEditItem={() => handleClickEditContract(index)}
                      onClickRemoveItem={() => handleClickRemoveContract(index)}
                    />
                  ))
                ) : (
                  <div className="flex bg-gray-200 p-4">
                    <span className="font-medium text-sm text-gray-600">
                      Nenhum contrato registrado
                    </span>
                  </div>
                )}
                <div
                  className="flex justify-center items-center cursor-pointer ml-2 md:ml-10"
                  onClick={handleClickNewContract}
                >
                  <PlusIcon className="hover:text-white hover:bg-teal-600 hover:scale-110 h-9 w-9 text-teal-600 transition duration-300 delay-75 ease-in-out rounded-md" />
                </div>
              </div>
              <div>
                <SelectForm
                  name="businessGroupId"
                  label="Grupo Econômico"
                  options={optionsBusiness}
                />
              </div>
              {contractError && (
                <span className="text-red-500 text-xs py-2 px-1">{contractError}</span>
              )}
            </div>
          </div>
          <div className="border-dashed border border-gray-300">
            <div className="flex flex-col p-6 w-full mx-auto">
              <span className="text-lg font-bold text-gray-600 mb-4">Contato</span>
              <div className="flex flex-col md:flex-row justify-between md:gap-10 lg:gap-0">
                <div className="w-full md:w-1/2 lg:mr-20 xl:mr-36 2xl:mr-60 gap-4">
                  <InputForm
                    type="email"
                    name="email"
                    label="Email*"
                    placeholder="Digite um email"
                  />
                </div>
                <div className="w-full md:w-1/2 lg:mr-20 xl:mr-36 2xl:mr-60 gap-4">
                  <InputMaskForm
                    name="phone"
                    label="Celular*"
                    maskChar={null}
                    inputMask="(99) 99999-9999"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="border-dashed border border-gray-300">
            <div className="flex flex-col p-6 w-full mx-auto">
              <span className="text-lg font-bold text-gray-600 mb-4">Endereço</span>
              <div className="flex flex-col md:flex-row justify-between md:gap-10 lg:gap-0">
                <div className="flex flex-col w-full md:w-1/2 lg:mr-20 xl:mr-36 2xl:mr-60 gap-4">
                  <InputMaskForm
                    name="address.zip"
                    label="Cep*"
                    maskChar={null}
                    inputMask="99999-999"
                  />
                  <InputForm
                    type="text"
                    name="address.streetAndNumber"
                    label="Rua e número*"
                    placeholder="Digite uma rua"
                  />
                </div>
                <div className="flex flex-col w-full md:w-1/2 lg:mr-20 xl:mr-36 2xl:mr-60 gap-4">
                  <InputForm
                    type="text"
                    name="address.neighborhood"
                    label="Bairro*"
                    placeholder="Digite um bairro"
                  />
                  <InputForm
                    type="text"
                    name="address.complement"
                    label="Complemento"
                    placeholder="Digite um complemento"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="border-dashed border border-gray-300">
            <div className="flex flex-col p-6 w-full mx-auto">
              <span className="text-lg font-bold text-gray-600 mb-4">Perfil</span>
              <div className="flex flex-col md:flex-row justify-between md:gap-10 lg:gap-0">
                <div className="flex flex-col w-full md:w-1/2 lg:mr-20 xl:mr-36 2xl:mr-60 gap-4">
                  <CheckboxForm name="isAdmin" label="Administrador" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <NewContractModal
          contractToEdit={getSelectedContract()}
          isOpen={openNewContractModal}
          onClose={handleCloseNewContract}
          onConfirm={handleNewContract}
        />
      </Form>
      <ConfirmModal
        isOpen={openModalRemoveContract}
        onClose={() => setOpenModalRemoveContract(false)}
        modalText="Deseja remover essa contrato?"
        cancelButton={{
          buttonText: 'Cancelar',
          onClick: () => setOpenModalRemoveContract(false)
        }}
        confirmButton={{
          buttonText: 'Confirmar',
          onClick: handleRemoveContract
        }}
      />
    </div>
  );
};

export default CreateEmployee;
