import { ArrowLeftIcon } from '@heroicons/react/outline';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import Button from '../../../../components/Button';
import DropzoneForm from '../../../../components/FormComponents/DropzoneForm';
import InputForm from '../../../../components/FormComponents/InputForm';
import InputMaskForm from '../../../../components/FormComponents/InputMaskForm';
import ImagePreviewModal from '../../../../components/ImagePreviewModal';
import Switch from '../../../../components/Switch/Switch';
import TitleCard from '../../../../components/TitleCard';
import {
  getErrorMessage,
  getFieldErrors,
  manageApiErrorResponse
} from '../../../../helpers/handleApiErrorResponse';
import Routes from '../../../_shared/constants/Routes.enum';
import { FileDocument } from '../../../manageDocuments/models/Document';
import { CreateBusinessGroupDto } from '../../dtos/CreateBusinessGroupDto';
import { UpdateBusinessGroupDto } from '../../dtos/UpdateBusinessGroupDto';
import { BusinessGroup } from '../../models/BusinessGroup';
import businessGroupService from '../../service/businessGroup.service';
import ImageDetail from './components/ImageDetail';

type ParamsProps = { id: string };

interface Props {
  businessGroup?: BusinessGroup;
}

const CreateBusinessGroup = ({ businessGroup }: Props) => {
  const formRef = useRef<FormHandles>(null);
  const [isActive, setIsActive] = useState(false);
  const history = useHistory();
  const { id } = useParams<ParamsProps>();
  const [image, setImage] = useState<FileDocument>();
  const [file, setFile] = useState<File>();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (businessGroup) {
      setIsActive(businessGroup.isActive);
    }
  }, [businessGroup]);

  const handleChangeSelectImage = (file: File) => {
    setImage(undefined);
    setFile(file);
  };

  const createOrUpdateBusinessGroup = async () => {
    const mainFormData = formRef.current?.getData();
    const dto = { ...mainFormData, isActive: isActive };

    try {
      if (id) {
        const updateBusinessGroupDto = {
          ...dto
        } as UpdateBusinessGroupDto;
        const result = await businessGroupService.updateBusinessGroup(id, updateBusinessGroupDto);
        toast.success(result.message);
      } else {
        const createBusinessGroupDto = {
          ...dto
        } as CreateBusinessGroupDto;
        const result = await businessGroupService.createBusinessGroup(createBusinessGroupDto);
        toast.success(result.message);
      }
      clearForm();
      history.push(Routes.LIST_BUSINESS);
    } catch (error) {
      handleError(error);
    }
  };

  const handleBusinessGroupActive = (value: boolean) => {
    if (value) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  };

  const handleError = (resultError: unknown) => {
    const fieldsErrors = getFieldErrors(resultError);
    formRef.current?.setErrors(fieldsErrors);
    const resultErrorResponse = manageApiErrorResponse(resultError);
    const errorMessage = getErrorMessage(resultErrorResponse);
    toast.error(errorMessage);
  };

  const handleShowImage = () => {
    setIsOpen(true);
  };

  const handleRemoveImage = () => {
    setImage(undefined);
    setFile(undefined);
    formRef.current?.clearField('image');
  };

  const handleCloseImage = () => {
    setIsOpen(false);
  };

  const imageUrl = (imageName: string) => {
    return businessGroupService.getImageUrl(imageName);
  };

  const clearForm = () => {
    formRef.current?.reset();
  };

  const loadBusinessGroup = async () => {
    try {
      const result = await businessGroupService.getBusinessGroupById(id);
      formRef.current?.setFieldValue('name', result.data.name);
      formRef.current?.setFieldValue('cnpj', result.data.cnpj);
      setIsActive(result.data.isActive);
      setImage(result.data.file);
    } catch (error) {}
  };

  useEffect(() => {
    if (id) loadBusinessGroup();
  }, [id]);

  return (
    <div className="flex flex-col">
      <div className="flex sm:flex-row flex-col justify-between">
        <TitleCard text={id ? 'Editar CNPJ' : 'Novo CNPJ'} />
        <div className="flex justify-end items-center sm:pr-6 gap-2">
          <button
            className="bg-gray-100 hover:bg-gray-200 rounded flex flex-row items-center text-center font-medium text-base cursor-pointer px-2 py-1"
            onClick={() => history.goBack()}
          >
            <ArrowLeftIcon className="w-3 h-3 mr-1" />
            <span className="font-medium text-base">Voltar</span>
          </button>
          <div className="items-center justify-end sm:px-1 py-3">
            <Button variant="primary" type="button" onClick={createOrUpdateBusinessGroup}>
              Salvar {id && 'alterações'}
            </Button>
          </div>
        </div>
      </div>
      <div className="flex flex-col sm:p-6 p-1">
        <Form
          ref={formRef}
          initialData={businessGroup}
          onSubmit={createOrUpdateBusinessGroup}
          className="flex flex-col gap-6"
        >
          <div className="flex flex-col gap-4">
            <div className="flex flex-col sm:flex-row gap-2 justify-between">
              <div className="w-full">
                <InputForm name="name" type="text" label="Nome" />
              </div>
              <div className="w-auto">
                <InputMaskForm
                  name="cnpj"
                  maskChar={null}
                  inputMask="99.999.999/9999-99"
                  label="CNPJ"
                  inputStyle="text-center"
                />
              </div>
            </div>
            <div className="flex align-middle">
              <Switch modalText="Ativo:" value={isActive} onChange={handleBusinessGroupActive} />
            </div>
            <div className="flex flex-col gap-3">
              {(file || image) && (
                <div className="flex flex-col">
                  <ImageDetail
                    showImage={handleShowImage}
                    removeImage={handleRemoveImage}
                    image={image}
                    file={file}
                  />
                </div>
              )}
              <div>
                <DropzoneForm
                  onChange={handleChangeSelectImage}
                  name="file"
                  label={`${id ? 'atualizar' : 'selecionar'} um arquivo PNG, JPG ou JPEG.`}
                  acceptFiles={{
                    'image/*': ['.png', '.jpg', '.jpeg']
                  }}
                />
              </div>
            </div>
          </div>
        </Form>
      </div>
      <ImagePreviewModal
        getUrlImage={imageUrl}
        image={image}
        file={file}
        isOpen={isOpen}
        onClose={handleCloseImage}
      />
    </div>
  );
};

export default CreateBusinessGroup;
