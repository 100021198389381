import { Disclosure } from '@headlessui/react';
import {
  DocumentTextIcon,
  FolderIcon,
  FolderOpenIcon,
  PencilIcon,
  PhotographIcon,
  TrashIcon
} from '@heroicons/react/solid';

import { CornerDownRight, FolderLock } from 'lucide-react';
import { Action } from '../../../../../../../components/Table';
import { useAuth } from '../../../../../../auth/contexts/AuthProvider';
import { Directory } from '../../../../../models/Directory';
import { Document } from '../../../../../models/Document';
import DropdownDisclosure from './DropdownDisclosure';

type Props = {
  subDirectory: Directory;
  viewDocument: (document: Document) => void;
  onClickEditDocument: (documentId: string) => void;
  onClickDeleteDocument: (document: string) => void;
  onClickEditDirectory: (directoryId: number) => void;
  onClickAgroupDirectory: (directoryId: number) => void;
  child?: boolean;
};

const ChildDisclosure = ({
  subDirectory,
  viewDocument,
  onClickDeleteDocument,
  onClickEditDocument,
  onClickEditDirectory,
  onClickAgroupDirectory,
  child
}: Props) => {
  const { user } = useAuth();

  const actions: Action[] = [
    { label: 'Editar', onClick: onClickEditDirectory },
    { label: 'Subdiretório', onClick: onClickAgroupDirectory }
  ];

  const handleDocumentIcon = (document: Document) => {
    const documentIcon = document.file.mimetype.split('/');
    if (documentIcon[0] === 'image') {
      return <PhotographIcon className="md:h-6 md:w-6 h-5 w-5 text-teal-600" />;
    } else {
      return <DocumentTextIcon className="md:h-6 md:w-6 h-5 w-5 text-teal-600" />;
    }
  };

  return (
    <div className={`${child && 'md:ml-[44px] ml-[8px]'}`}>
      <Disclosure>
        {({ open }) => (
          <>
            <div className="flex transition-all">
              <Disclosure.Button
                className={`px-3 py-2 md:ml-0 ml-[6px] gap-2 items-center cursor-pointer w-full flex`}
              >
                <CornerDownRight className="h-6 w-6 text-teal-600 ml-1 sm:block hidden" />
                {subDirectory.isActive ? (
                  <>
                    {open ? (
                      <FolderOpenIcon className="md:h-8 md:w-8 h-6 w-6 text-teal-600" />
                    ) : (
                      <FolderIcon className="md:h-8 md:w-8 h-6 w-6 text-teal-600" />
                    )}
                  </>
                ) : (
                  <div className="relative">
                    <FolderLock className="mx-1 md:h-6 md:w-6 h-5 w-5 text-stone-600" />
                  </div>
                )}
                <span className="font-medium text-gray-600 hover:font-semibold line-clamp-1">
                  {subDirectory.name}
                </span>
              </Disclosure.Button>
              {user?.isAdmin && (
                <DropdownDisclosure
                  values={subDirectory}
                  menuItems={actions}
                  className="relative flex items-center justify-center pr-3"
                />
              )}
            </div>
            <Disclosure.Panel>
              {/* <div className="flex sm:flex-col flex-col-reverse"> */}
              {subDirectory.subDirectories &&
                subDirectory.subDirectories?.length > 0 &&
                subDirectory.subDirectories.map((item, index) => (
                  <div key={index}>
                    {user?.isAdmin && item ? (
                      <ChildDisclosure
                        subDirectory={item}
                        onClickDeleteDocument={onClickDeleteDocument}
                        onClickEditDocument={onClickEditDocument}
                        onClickEditDirectory={onClickEditDirectory}
                        onClickAgroupDirectory={onClickAgroupDirectory}
                        viewDocument={viewDocument}
                        child={true}
                      />
                    ) : (
                      item.isActive &&
                      (item.documents?.length !== 0 || item.subDirectories?.length !== 0) && (
                        <ChildDisclosure
                          subDirectory={item}
                          onClickDeleteDocument={onClickDeleteDocument}
                          onClickEditDocument={onClickEditDocument}
                          onClickEditDirectory={onClickEditDirectory}
                          onClickAgroupDirectory={onClickAgroupDirectory}
                          viewDocument={viewDocument}
                          child={true}
                        />
                      )
                    )}
                  </div>
                ))}
              {subDirectory.documents?.map((document, index) => (
                <div key={index}>
                  <div className="flex sm:bg-opacity-0 border-t border-white sm:pl-[57px] pl-5 py-2 justify-between">
                    <div
                      className="flex gap-1 pt-1 items-center border-stone-500 text-gray-600 font-medium cursor-pointer hover:font-semibold"
                      onClick={() => viewDocument(document)}
                    >
                      {handleDocumentIcon(document)}
                      <span className="line-clamp-1">{document.title}</span>
                    </div>
                    {user?.isAdmin && (
                      <div className="mr-4 mt-1.5 flex justify-between gap-2">
                        <TrashIcon
                          className="sm:w-5 sm:h-5 w-4 h-4 cursor-pointer hover:text-red-500 text-red-600"
                          onClick={() => onClickDeleteDocument(document.id)}
                        />
                        <PencilIcon
                          className="sm:w-5 sm:h-5 w-4 h-4 cursor-pointer text-teal-600 hover:text-teal-500"
                          onClick={() => onClickEditDocument(document.id)}
                        />
                      </div>
                    )}
                  </div>
                </div>
              ))}
              {/* </div> */}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default ChildDisclosure;
