import { Dialog, Transition } from '@headlessui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Fragment, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import Button from '../../../../../../components/Button';
import DatepickerForm from '../../../../../../components/FormComponents/DatepickerForm';
import DropzoneForm from '../../../../../../components/FormComponents/DropzoneForm';
import SelectForm, { OptionSelect } from '../../../../../../components/FormComponents/SelectForm';
import { convertToDate } from '../../../../../../helpers/convertToDate';
import {
  getErrorMessage,
  getFieldErrors,
  manageApiErrorResponse
} from '../../../../../../helpers/handleApiErrorResponse';
import { flattenBusinessGroup } from '../../../../../_shared/helpers/FlattenBusinessGroup';
import businessGroupService from '../../../../../businessGroup/service/businessGroup.service';
import { CreateAggregatedHoleriteDto } from '../../../../dtos/CreateAggregatedHoleriteDto';
import AggregatedHoleriteService from '../../../../service/aggregatedHolerite.service';
import FileDetail from '../FileDetail';

type ConfirmProps = { aggregatedHolerite: any };

interface ConfigModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const NewAggregatedHoleriteModal = ({ isOpen, onClose, onConfirm }: ConfigModalProps) => {
  const formRef = useRef<FormHandles>(null);
  const [file, setFile] = useState<File>();
  const [optionsBusiness, setOptionsBusiness] = useState<OptionSelect[]>([]);

  const handleAddNewAggregatedHolerite = async () => {
    try {
      const mainFormData = formRef.current?.getData();
      const newHoleriteToCreate = {
        ...mainFormData,
        creditDate: convertToDate(mainFormData!.creditDate)
      } as CreateAggregatedHoleriteDto;

      const result = await AggregatedHoleriteService.newAggregatedHolerite(newHoleriteToCreate);

      toast.success(result.message);

      onClose();
      onConfirm();
      clearForm();
    } catch (error) {
      handleErrors(error);
    }
  };

  const getOptionsBusiness = async () => {
    const response = await businessGroupService.getBusinessGroup();
    const { results: businessGroups } = response;
    const flattenedOptions = flattenBusinessGroup(businessGroups || []);
    setOptionsBusiness(flattenedOptions);
  };

  const handleErrors = (resultError: unknown) => {
    const fieldsErrors = getFieldErrors(resultError);
    formRef.current?.setErrors(fieldsErrors);
    const resultErrorResponse = manageApiErrorResponse(resultError);
    const errorMessage = getErrorMessage(resultErrorResponse);

    toast.error(errorMessage);
  };

  const handleCancel = () => {
    onClose();
    clearForm();
  };

  const clearForm = () => {
    formRef.current?.reset();
  };

  const handleHoleriteFile = (file: File) => {
    setFile(file);
  };

  const handleRemoveFile = () => {
    setFile(undefined);
    formRef.current?.clearField('holerite_file');
  };

  useEffect(() => {
    getOptionsBusiness();
  }, [optionsBusiness]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={onClose}>
        <div className="min-h-screen px-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="flex overflow-y-auto overflow-x-hidden fixed top-4 z-50 justify-center items-center h-full md:h-full inset-0">
              <div className="relative px-4 w-full max-w-2xl h-auto mx-3">
                <Form
                  ref={formRef}
                  onSubmit={handleAddNewAggregatedHolerite}
                  className="flex justify-center"
                >
                  <div className="relative bg-white rounded-lg shadow w-full sm:w-3/5 ">
                    <div className="flex flex-col items-start p-4 rounded-t border-b">
                      <span className="text-xl font-semibold text-gray-title lg:text-2xl">
                        Novo envio
                      </span>
                      <span className="text-gray-title">
                        Enviar novo arquivo de holerite para todos os usuários
                      </span>
                    </div>
                    <div className="p-6 space-y-3">
                      <DatepickerForm name="creditDate" label="Data de crédito" value="" />
                      <SelectForm
                        name="businessGroupId"
                        label="Grupo Econômico"
                        options={optionsBusiness}
                      />
                      {file && (
                        <div className="flex flex-col">
                          <FileDetail removeImage={handleRemoveFile} file={file} />
                        </div>
                      )}
                      <DropzoneForm
                        name="txtFile"
                        onChange={handleHoleriteFile}
                        label="selecionar um arquivo txt."
                        acceptFiles={{ 'text/plain': ['.txt'] }}
                      />
                    </div>
                    <div className="flex items-center justify-end p-6 space-x-3 rounded-b border-t border-gray-200">
                      <Button variant="default" type="button" onClick={handleCancel}>
                        Cancelar
                      </Button>
                      <Button
                        variant="primary"
                        type="button"
                        onClick={handleAddNewAggregatedHolerite}
                      >
                        Salvar
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default NewAggregatedHoleriteModal;
