import { LogoutIcon } from '@heroicons/react/outline';
import { UserCircleIcon } from '@heroicons/react/solid';
import React, { useState } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { useAuth } from '../../modules/auth/contexts/AuthProvider';
import Routes from '../../modules/_shared/constants/Routes.enum';
import Footer from '../Footer';

interface Props {
  children: React.ReactNode;
}

const MainLayout: React.FC<Props> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const { signOut, user } = useAuth();
  const history = useHistory();
  const activeMenuStyle = 'border-l-4 border-teal-500 bg-neutral-600 ';

  const toggleOpen = () => {
    setOpen((old) => !old);
  };

  const logout = () => {
    signOut();
    history.push(Routes.LOGIN);
  };

  return (
    <>
      {user ? (
        <div className="flex flex-col md:flex-row w-full h-screen">
          <div
            className={`text-white w-full md:w-60 lg:w-80 md:h-full ${
              open
                ? 'bg-white text-black'
                : 'bg-stone-900 shadow-lg md:fixed z-50 md:z-0 block md:flex'
            }`}
          >
            <div className="flex flex-row md:flex-col p-2 md:p-6 items-center md:items-start overflow-auto">
              <div className="grow md:grow-0 ml-1 md:ml-0 md:mb-24">
                <div className="w-32 p-2">
                  <Link to={Routes.HOME}>
                    <img
                      src={
                        open
                          ? '/images/logo_inovvati_colorida.svg'
                          : '/images/logo_inovvati_branca.svg'
                      }
                      className="w-full h-auto"
                      alt="Inovvati Logo"
                    />
                  </Link>
                </div>
              </div>
              <div className="md:hidden visible text-right place-content-end px-3">
                <div onClick={toggleOpen}>
                  {open ? (
                    <svg
                      className="h-8 w-8 text-black"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  ) : (
                    <svg
                      className={`h-8 w-8 text-white`}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <line x1="3" y1="12" x2="21" y2="12" /> <line x1="3" y1="6" x2="21" y2="6" />{' '}
                      <line x1="3" y1="18" x2="21" y2="18" />
                    </svg>
                  )}
                </div>
              </div>
              <div className="md:flex flex-col hidden md:w-full bg-stone-700">
                <div className="flex flex-col">
                  <NavLink to={Routes.HOME} activeClassName={activeMenuStyle}>
                    <div className="px-4 py-3 cursor-pointer w-full font-medium text-base hover:text-teal-400 hover:bg-gray-100 hover:bg-opacity-10 transition-all ease-in-out rounded-sm">
                      Inicio
                    </div>
                  </NavLink>
                </div>
                <div className="flex flex-col">
                  <NavLink to={Routes.LIST_USER_HOLERITES} activeClassName={activeMenuStyle}>
                    <div className="px-4 py-3 cursor-pointer w-full font-medium text-base hover:text-teal-400 hover:bg-gray-100 hover:bg-opacity-10 transition-all ease-in-out ">
                      Holerites
                    </div>
                  </NavLink>
                </div>

                <div className="flex flex-col">
                  <NavLink to={Routes.LIST_REPORT_INCOME} activeClassName={activeMenuStyle}>
                    <div className="px-4 py-2 cursor-pointer w-full font-medium text-base hover:text-teal-400 hover:bg-gray-100 hover:bg-opacity-10 transition-all ease-in-out rounded-sm">
                      Informe de Rendimento
                    </div>
                  </NavLink>
                </div>

                <div className="flex flex-col">
                  <NavLink to={Routes.LIST_DOCUMENTS} activeClassName={activeMenuStyle}>
                    <div className="px-4 py-2 cursor-pointer w-full font-medium text-base hover:text-teal-400 hover:bg-gray-100 hover:bg-opacity-10 transition-all ease-in-out rounded-sm">
                      Documentos
                    </div>
                  </NavLink>
                </div>
              </div>

              {user?.isAdmin && (
                <div className="mt-10 md:block hidden">
                  <span className="px-4 py-2 block text-stone-300">Gerenciar</span>
                  <div className="md:flex flex-col bg-stone-700 ">
                    <div className="flex flex-col">
                      <NavLink to={Routes.LIST_HOLERITES_MANAGER} activeClassName={activeMenuStyle}>
                        <div className="px-4 py-3 cursor-pointer w-full font-medium text-base hover:text-teal-400 hover:bg-gray-100 hover:bg-opacity-10 transition-all ease-in-out ">
                          Administrar Holerites
                        </div>
                      </NavLink>
                    </div>

                    <div className="flex flex-col">
                      <NavLink to={Routes.LIST_BUSINESS} activeClassName={activeMenuStyle}>
                        <div className="px-4 py-3 cursor-pointer w-full font-medium text-base hover:text-teal-400 hover:bg-gray-100 hover:bg-opacity-10 transition-all ease-in-out ">
                          Grupo Econômico
                        </div>
                      </NavLink>
                    </div>

                    <div className="flex flex-col">
                      <NavLink to={Routes.LIST_EMPLOYEES} activeClassName={activeMenuStyle}>
                        <div className="px-4 py-3 cursor-pointer w-full font-medium text-base hover:text-teal-400 hover:bg-gray-100 hover:bg-opacity-10 transition-all ease-in-out rounded-sm">
                          Funcionários
                        </div>
                      </NavLink>
                    </div>

                    <div className="flex flex-col">
                      <NavLink
                        to={Routes.LIST_FILE_REPORT_INCOME}
                        activeClassName={activeMenuStyle}
                      >
                        <div className="px-4 py-3 cursor-pointer w-full font-medium text-base hover:text-teal-400 hover:bg-gray-100 hover:bg-opacity-10 transition-all ease-in-out rounded-sm">
                          Envio de Informe de Rendimento
                        </div>
                      </NavLink>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <ul
              className={`flex flex-col bg-white text-black gap-4 p-4 text-center ${
                open ? '' : 'hidden'
              }`}
            >
              <li
                className="cursor-pointer hover:bg-stone-100 hover:text-teal-400 hover:font-semibold transition-all ease-in-out w-full py-3 rounded"
                onClick={() => history.push(Routes.HOME)}
              >
                Inicio
              </li>
              <li
                className="cursor-pointer hover:bg-stone-100 hover:text-teal-400 hover:font-semibold transition-all ease-in-out w-full py-3 rounded"
                onClick={() => history.push(Routes.LIST_USER_HOLERITES)}
              >
                Holerites
              </li>
              <li
                className="cursor-pointer hover:bg-stone-100 hover:text-teal-400 hover:font-semibold transition-all ease-in-out w-full py-3 rounded"
                onClick={() => history.push(Routes.LIST_REPORT_INCOME)}
              >
                Informe de Rendimento
              </li>

              <li
                className="cursor-pointer hover:bg-stone-100 hover:text-teal-400 hover:font-semibold transition-all ease-in-out w-full py-3 rounded"
                onClick={() => history.push(Routes.LIST_DOCUMENTS)}
              >
                Documentos
              </li>

              {user?.isAdmin && (
                <>
                  <li
                    className="cursor-pointer hover:bg-stone-100 hover:text-teal-400 hover:font-semibold transition-all ease-in-out w-full py-3 rounded"
                    onClick={() => history.push(Routes.LIST_HOLERITES_MANAGER)}
                  >
                    Administrar Holerites
                  </li>
                  <li
                    className="cursor-pointer hover:bg-stone-100 hover:text-teal-400 hover:font-semibold transition-all ease-in-out w-full py-3 rounded"
                    onClick={() => history.push(Routes.LIST_BUSINESS)}
                  >
                    Grupo Econômico
                  </li>
                  <li
                    className="cursor-pointer hover:bg-stone-100 hover:text-teal-400 hover:font-semibold transition-all ease-in-out w-full py-3 rounded"
                    onClick={() => history.push(Routes.LIST_EMPLOYEES)}
                  >
                    Funcionários
                  </li>
                  <li
                    className="cursor-pointer hover:bg-stone-100 hover:text-teal-400 hover:font-semibold transition-all ease-in-out w-full py-3 rounded"
                    onClick={() => history.push(Routes.LIST_FILE_REPORT_INCOME)}
                  >
                    Envio de Informe de Rendimento
                  </li>
                </>
              )}
              <li
                className="cursor-pointer text-red-500 border-2 border-red-500 transition-all ease-in-out w-full py-3 rounded"
                onClick={logout}
              >
                Sair
              </li>
            </ul>
          </div>
          <div className="flex flex-col bg-gray-100 w-full h-full lg:ml-80 md:ml-60 overflow-y-auto">
            <div className="hidden md:flex items-center py-4 px-5 justify-between gap-5 font-semibold text-gray-500 cursor-default">
              <div className="flex items-center gap-2">
                <UserCircleIcon className="w-8 h-8 text-gray-500 cursor-default" />
                <span className="capitalize">
                  Olá, {user?.name.split(' ').shift()?.toLocaleLowerCase()}!
                </span>
              </div>

              <div
                className="flex items-center border-red-500 text-red-500 border-2 py-1 px-3 rounded gap-2 cursor-pointer"
                onClick={logout}
              >
                <span>Sair</span>
                <LogoutIcon
                  className="alt='Sair'flex justify-end  transition-all ease-in-out w-7 rounded"
                  name="Sair"
                />
              </div>
            </div>

            <div className="grow border-2 border-gray-300 bg-white rounded-sm mx-3 sm:mt-0 mt-4 py-4 px-5">
              {children}
            </div>
            <Footer />
          </div>
        </div>
      ) : (
        <div className="flex flex-col h-screen">
          <div
            className={`w-full shadow-lg text-white
              ${open ? 'bg-white text-black' : 'bg-stone-900 shadow-lg'}`}
          >
            <div className="xl:max-w-6xl mx-auto">
              <div className="flex flex-row xl:px-0 md:px-10 px-6 py-2 items-center flex-wrap">
                <div className="grow flex flex-row gap-2 items-center">
                  <Link to="/">
                    <div className="w-28 py-2">
                      <img
                        src={
                          open
                            ? '/images/logo_inovvati_colorida.svg'
                            : '/images/logo_inovvati_branca.svg'
                        }
                        className="w-full h-auto"
                        alt="Inovvati Logo"
                      />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="grow w-full xl:max-w-6xl mx-auto xl:px-0 md:px-10 px-4 py-10">
            {children}
          </div>
          <Footer />
        </div>
      )}
    </>
  );
};

export default MainLayout;
