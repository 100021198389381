import { Action } from '../../../../../../components/Table';
import { useAuth } from '../../../../../auth/contexts/AuthProvider';
import { Directory } from '../../../../models/Directory';
import { Document } from '../../../../models/Document';
import DisclosureItem from './components/DisclosureItem';

type Props = {
  groupedDirectories?: Directory[];
  viewDocument: (document: Document) => void;
  onClickEditDocument: (documentId: string) => void;
  onClickDeleteDocument: (documentId: string) => void;
  onClickEditDirectory: (directoryId: number) => void;
  onClickAgroupDirectory: (directoryId: number) => void;
};

const DisclosurePanels = ({
  groupedDirectories,
  viewDocument,
  onClickEditDocument,
  onClickDeleteDocument,
  onClickEditDirectory,
  onClickAgroupDirectory
}: Props) => {
  const { user } = useAuth();

  const actions: Action[] = [
    { label: 'Editar', onClick: onClickEditDirectory },
    { label: 'Adicionar', onClick: onClickAgroupDirectory }
  ];

  return (
    <>
      {groupedDirectories &&
        groupedDirectories.length > 0 &&
        groupedDirectories?.map((directory, index) => (
          <div className={`mb-2 bg-gray-100 rounded`} key={index}>
            {user?.isAdmin && directory ? (
              <DisclosureItem
                actions={actions}
                directory={directory}
                onClickAgroupDirectory={onClickAgroupDirectory}
                onClickDeleteDocument={onClickDeleteDocument}
                onClickEditDirectory={onClickEditDirectory}
                onClickEditDocument={onClickEditDocument}
                viewDocument={viewDocument}
              />
            ) : (
              directory.isActive &&
              (directory.documents?.length !== 0 || directory.subDirectories?.length !== 0) && (
                <DisclosureItem
                  actions={actions}
                  directory={directory}
                  onClickAgroupDirectory={onClickAgroupDirectory}
                  onClickDeleteDocument={onClickDeleteDocument}
                  onClickEditDirectory={onClickEditDirectory}
                  onClickEditDocument={onClickEditDocument}
                  viewDocument={viewDocument}
                />
              )
            )}
          </div>
        ))}
    </>
  );
};

export default DisclosurePanels;
