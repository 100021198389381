import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import Button from '../../../../../components/Button';
import Loading from '../../../../../components/Loading';

interface ConfigModalProps {
  isOpen: boolean;
  closeModal: () => void;
  urlReportIncome: string;
}

const ReportIncomeViewModal = ({ isOpen, closeModal, urlReportIncome }: ConfigModalProps) => {
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={closeModal}>
          <div className="min-h-screen px-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="flex overflow-y-auto overflow-x-hidden fixed z-50 justify-center items-cent inset-0 border-y-0">
                <div className="relative p-6 mb-12 w-full h-auto mx-3">
                  <div className="relative bg-white rounded-lg w-full h-full shadow rounded-b-lg">
                    <div className="flex items-center justify-end p-2 space-x-3 border-gray-200">
                      <Button onClick={closeModal} variant="default" type="button">
                        Fechar
                      </Button>
                    </div>

                    {!urlReportIncome ? (
                      <Loading />
                    ) : (
                      <iframe
                        style={{
                          width: '100%',
                          height: '100%',
                          borderEndStartRadius: '8px',
                          borderEndEndRadius: '8px'
                        }}
                        src={urlReportIncome}
                      />
                    )}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ReportIncomeViewModal;
