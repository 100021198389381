import { Dialog, Transition } from '@headlessui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Fragment, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import Button from '../../../../../../../../components/Button';
import DatepickerForm from '../../../../../../../../components/FormComponents/DatepickerForm';
import Switch from '../../../../../../../../components/Switch/Switch';
import { convertDateToString, convertToDate } from '../../../../../../../../helpers/convertToDate';
import {
  getErrorMessage,
  getFieldErrors,
  manageApiErrorResponse
} from '../../../../../../../../helpers/handleApiErrorResponse';
import { ListEmployeesDto } from '../../../../../../dtos/ListEmployeeDto';

interface ConfigModalProps {
  isOpen: boolean;
  onClose: () => void;
  onChange: (value: ListEmployeesDto) => void;
  filterActive?: ListEmployeesDto;
}

const FilterEmployeesModal = ({ isOpen, onClose, onChange, filterActive }: ConfigModalProps) => {
  const [filterCurrent, setFilterCurrent] = useState<any>(filterActive);
  const [employeesActive, setEmployeesActive] = useState(false);
  const [employeesInactive, setEmployeesInactive] = useState(false);
  const [employeesAdmittedMonth, setEmployeesAdmittedMonth] = useState(false);
  const [employeesDismissedMonth, setEmployeesDismissedMonth] = useState(false);
  const formRef = useRef<FormHandles>(null);

  const validateEmployeeActiveOrInactive = () => {
    if (employeesActive && !employeesInactive) {
      return true;
    } else if (employeesInactive && !employeesActive) {
      return false;
    } else {
      return;
    }
  };

  const handleEmployeesActive = (value: boolean) => {
    if (value) {
      handleEmployeesInactive(false);
      handleEmployeesAdmitted(false);
      handleEmployeesDismissed(false);
    }
    setEmployeesActive(value);
  };

  const handleEmployeesInactive = (value: boolean) => {
    if (value) {
      handleEmployeesActive(false);
      handleEmployeesAdmitted(false);
      handleEmployeesDismissed(false);
    }
    setEmployeesInactive(value);
  };

  const handleEmployeesAdmitted = (value: boolean) => {
    if (value) {
      handleEmployeesActive(false);
      handleEmployeesInactive(false);
      handleEmployeesDismissed(false);
    }
    setEmployeesAdmittedMonth(value);
  };

  const handleEmployeesDismissed = (value: boolean) => {
    if (value) {
      handleEmployeesActive(false);
      handleEmployeesInactive(false);
      handleEmployeesAdmitted(false);
    }
    setEmployeesDismissedMonth(value);
  };

  const getCurrentMonthDates = (value: boolean) => {
    if (value) {
      const yearCurrent = new Date().getFullYear();
      const endDate = new Date(yearCurrent, new Date().getMonth() + 1, 0);
      const startDate = new Date(yearCurrent, new Date().getMonth(), 1);
      return { startDate, endDate };
    } else {
      return;
    }
  };

  const getDateRange = (value: string) => {
    const startDate = convertToDate(value);
    const endDate = convertToDate(value);
    if (startDate instanceof Date && endDate instanceof Date) {
      endDate.setHours(23);
      return { startDate, endDate };
    }
  };

  const handleStateDatepicker = () => {
    if (employeesActive || employeesInactive || employeesAdmittedMonth || employeesDismissedMonth) {
      formRef.current?.reset();
      return true;
    } else {
      return false;
    }
  };

  const handleFilterEmployee = async () => {
    try {
      const mainData = formRef.current?.getData();

      const admittedStartDate = getDateRange(mainData?.admittedDateRange.startDate);
      const dismissalEndDate = getDateRange(mainData?.dismissalDateRange.endDate);

      const filterValue: ListEmployeesDto = {
        employeesActive: validateEmployeeActiveOrInactive(),
        admittedDateRange: admittedStartDate
          ? admittedStartDate
          : getCurrentMonthDates(employeesAdmittedMonth),
        dismissalDateRange: dismissalEndDate
          ? dismissalEndDate
          : getCurrentMonthDates(employeesDismissedMonth)
      };

      onChange(filterValue);
      onClose();
    } catch (error) {
      handleErrors(error);
    }
  };

  const handleErrors = (resultError: unknown) => {
    const fieldsErrors = getFieldErrors(resultError);
    formRef.current?.setErrors(fieldsErrors);
    const resultErrorResponse = manageApiErrorResponse(resultError);
    const error = getErrorMessage(resultErrorResponse);
    toast.error(error);
  };

  const handleCancel = () => {
    onClose();
  };

  const clearForm = () => {
    formRef.current?.reset();
    setFilterCurrent(undefined);
    setEmployeesActive(false);
    setEmployeesInactive(false);
    setEmployeesAdmittedMonth(false);
    setEmployeesDismissedMonth(false);
  };

  const loadFilterEmployeers = () => {
    if (filterActive) {
      const newFilterActive = {
        ...filterActive,
        admittedDateRange: {
          startDate:
            !employeesAdmittedMonth &&
            convertDateToString(filterActive.admittedDateRange?.startDate)
        },
        dismissalDateRange: {
          endDate:
            !employeesDismissedMonth &&
            convertDateToString(filterActive.dismissalDateRange?.endDate)
        }
      };
      setFilterCurrent(newFilterActive);
    } else {
      return;
    }
  };

  useEffect(() => {
    loadFilterEmployeers();
    handleStateDatepicker();
  }, [filterActive]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={onClose}>
        <div className="min-h-screen px-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="flex overflow-y-auto overflow-x-hidden fixed top-4 z-50 justify-center items-center h-full md:h-full inset-0">
              <div className="relative px-4 w-full max-w-2xl h-auto mx-3">
                <Form
                  ref={formRef}
                  initialData={filterCurrent}
                  onSubmit={handleFilterEmployee}
                  className="flex justify-center"
                >
                  <div className="relative bg-white rounded-lg shadow w-full sm:w-3/5 ">
                    <div className="flex flex-col items-start p-4 rounded-t border-b">
                      <span className="text-xl font-semibold text-gray-title lg:text-2xl">
                        Filtrar funcionários por
                      </span>
                    </div>
                    <div className="p-6 space-y-3">
                      <Switch
                        modalText="Somente ativos"
                        onChange={handleEmployeesActive}
                        value={employeesActive}
                      />
                      <Switch
                        modalText="Somente desligados"
                        onChange={handleEmployeesInactive}
                        value={employeesInactive}
                      />
                      <Switch
                        modalText="Somente contratados no mês"
                        onChange={handleEmployeesAdmitted}
                        value={employeesAdmittedMonth}
                      />
                      <Switch
                        modalText="Somente desligados no mês"
                        onChange={handleEmployeesDismissed}
                        value={employeesDismissedMonth}
                      />
                      <DatepickerForm
                        name="admittedDateRange.startDate"
                        label="Data de início do contrato"
                        disabled={handleStateDatepicker()}
                      />
                      <DatepickerForm
                        name="dismissalDateRange.endDate"
                        label="Data término do contrato"
                        disabled={handleStateDatepicker()}
                      />
                    </div>
                    <div className="flex items-center justify-end p-6 space-x-3 rounded-b border-t border-gray-200">
                      <Button variant="default" type="button" onClick={handleCancel}>
                        Cancelar
                      </Button>
                      <Button variant="clean" type="button" onClick={clearForm}>
                        Limpar
                      </Button>
                      <Button variant="primary" type="button" onClick={handleFilterEmployee}>
                        Adicionar
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default FilterEmployeesModal;
