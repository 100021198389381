export const translateBoolean = (value: boolean) => {
  switch (value) {
    case true:
      return 'Sim';
    case false:
      return 'Não';
    default:
      return '';
  }
};

export const translateStatus = (value: boolean) => {
  switch (value) {
    case true:
      return 'Ativo';
    case false:
      return 'Inativo';
    default:
      return '';
  }
};
