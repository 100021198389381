import React from 'react';

interface Props {
  label: string;
  value: string;
}

const ItemCard: React.FC<Props> = ({ label, value }) => {
  return (
    <div className="flex flex-col w-full text-sm text-gray-500">
      <label className="py-1 font-medium">{label}</label>
      <span className="rounded border p-1 text-gray-600">{value ? value : '--/--/----'}</span>
    </div>
  );
};

export default ItemCard;
