import { DownloadIcon, EyeIcon } from '@heroicons/react/solid';
import saveAs from 'file-saver';
import { useState } from 'react';
import Button from '../../../../../../components/Button';
import ListCard from '../../../../../../components/ListCard';
import Table from '../../../../../../components/Table';
import { convertDateToString } from '../../../../../../helpers/convertToDate';
import { UserHolerite } from '../../../../models/UserHolerite';
import userHoleriteService from '../../../../service/userHolerite.service';
import UserHoleriteViewModal from '../UserHoleriteViewModal';

interface Props {
  UserHolerite?: UserHolerite[];
}

const UserHoleriteTable = ({ UserHolerite }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [holeriteId, setHoleriteId] = useState<number | undefined>(undefined);

  const handleDownload = async (itemActive: any) => {
    const response = await userHoleriteService.getPdfUrl(itemActive.id);
    const urlHolerite = URL.createObjectURL(response);
    saveAs(urlHolerite, `Holerite_${itemActive.period.replace('/', '_')}.pdf`);
  };

  function closeModal() {
    setIsOpen(!isOpen);
  }

  const validateDate = (value: any) => {
    return value != null ? new Date(value) : undefined;
  };

  const openModal = (holeriteId: any) => {
    setIsOpen(true);
    setHoleriteId(holeriteId);
  };

  const handleopenModal = async (holeriteId: any) => {
    setHoleriteId(holeriteId.id);
    const response = await userHoleriteService.getPdfUrl(holeriteId.id);
    window.open(URL.createObjectURL(response), 'blank');
  };

  return (
    <>
      <div className="sm:flex hidden">
        {isOpen && (
          <UserHoleriteViewModal holeriteId={holeriteId} isOpen={isOpen} closeModal={closeModal} />
        )}
        <Table
          columns={[
            {
              columnName: 'Data de Crédito',
              key: 'creditDate',
              transform: (value) => convertDateToString(validateDate(value))
            },
            {
              columnName: '',
              key: '',
              component: (value, itemActive) => (
                <div className="flex justify-end space-x-2">
                  <EyeIcon
                    onClick={() => openModal(itemActive.id)}
                    className="w-5 hover:text-gray-800 transition-all duration-300 cursor-pointer"
                  />
                  <button
                    onClick={() => {
                      handleDownload(itemActive);
                    }}
                  >
                    <DownloadIcon className="w-5 hover:text-gray-800 transition-all duration-300 cursor-pointer" />
                  </button>
                </div>
              )
            }
          ]}
          values={UserHolerite}
        />
      </div>
      <div className="flex sm:hidden">
        <ListCard
          itemsLabel={[
            {
              label: 'Data de Crédito',
              key: 'creditDate',
              transform: (value) => convertDateToString(validateDate(value))
            },
            {
              label: '',
              key: '',
              component: (value, itemActive) => (
                <div className="flex flex-col gap-2 sm:hidden w-full mt-[-16px]">
                  <Button styles="w-full" onClick={() => handleopenModal(itemActive)}>
                    Visualizar
                  </Button>
                  <Button
                    styles="w-full"
                    variant="primary"
                    onClick={() => {
                      handleDownload(itemActive);
                    }}
                  >
                    Download
                  </Button>
                </div>
              )
            }
          ]}
          values={UserHolerite}
        />
      </div>
    </>
  );
};

export default UserHoleriteTable;
