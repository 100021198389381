import { TrashIcon } from '@heroicons/react/solid';
import ListCard from '../../../../../../components/ListCard';
import Table from '../../../../../../components/Table';
import { FileReportIncome } from '../../../../models/FileReportIncome';

interface Props {
  filesReportsIncomes?: FileReportIncome[];
  onClickDeleteReportIncome: (ReportIncomeId: any) => void;
}

const FileReportIncomeTable = ({ filesReportsIncomes, onClickDeleteReportIncome }: Props) => {
  return (
    <>
      <div className="sm:flex hidden">
        <Table
          columns={[
            {
              columnName: 'Ano de Referência',
              key: 'referenceYear'
            },
            {
              columnName: '',
              key: '',
              component: (value, itemActive) => (
                <div className="flex justify-end space-x-2">
                  <TrashIcon
                    className="w-5 cursor-pointer text-red-600 hover:text-red-500"
                    onClick={() => onClickDeleteReportIncome(itemActive.id)}
                  />
                </div>
              )
            }
          ]}
          values={filesReportsIncomes}
        />
      </div>
      <div className="flex sm:hidden">
        <ListCard
          itemsLabel={[
            {
              label: 'Ano de Referência',
              key: 'referenceYear'
            }
          ]}
          values={filesReportsIncomes}
        />
      </div>
    </>
  );
};

export default FileReportIncomeTable;
