import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import MainLayout from '../components/MainLayout';
import Routes from '../modules/_shared/constants/Routes.enum';
import ListAggregatedHolerite from '../modules/aggregatedHolerite/pages/ListAggregatedHolerite';
import { useAuth } from '../modules/auth/contexts/AuthProvider';
import CreateBusinessGroup from '../modules/businessGroup/pages/CreateBusinessGroup';
import ListBusinessGroup from '../modules/businessGroup/pages/ListBusinessGroup';
import CreateEmployee from '../modules/employees/pages/CreateEmployee';
import ListEmployee from '../modules/employees/pages/ListEmployee';
import ListUserHolerite from '../modules/holerite/pages/ListUserHolerite';
import Home from '../modules/home';
import CreateDocument from '../modules/manageDocuments/pages/CreateDocument';
import ListDirectory from '../modules/manageDocuments/pages/ListDirectory';
import ListDocuments from '../modules/manageDocuments/pages/ListDocuments';
import ListFileReportIncome from '../modules/reportIncome/pages/ListFileReportIncome';
import ListReportIncome from '../modules/reportIncome/pages/ListReportIncome';

const AppRoutes: React.FC = () => {
  const { user } = useAuth();

  return (
    <HashRouter>
      <MainLayout>
        <Switch>
          <Route path={Routes.HOME} exact component={Home} />
          <Route path={Routes.LIST_USER_HOLERITES} exact component={ListUserHolerite} />
          <Route path={Routes.LIST_DOCUMENTS} exact component={ListDocuments} />
          <Route path={Routes.LIST_REPORT_INCOME} exact component={ListReportIncome} />
          {user?.isAdmin && (
            <>
              <Route path={Routes.EDIT_EMPLOYEE} exact component={CreateEmployee} />
              <Route path={Routes.LIST_EMPLOYEES} exact component={ListEmployee} />
              <Route
                path={Routes.LIST_HOLERITES_MANAGER}
                exact
                component={ListAggregatedHolerite}
              />
              <Route path={Routes.LIST_USER_HOLERITES_ADMIN} exact component={ListUserHolerite} />
              <Route path={Routes.CREATE_EMPLOYEE} exact component={CreateEmployee} />
              <Route path={Routes.DIRECTORIES} exact component={ListDirectory} />
              <Route path={Routes.CREATE_DOCUMENT} exact component={CreateDocument} />
              <Route path={Routes.EDIT_DOCUMENT} exact component={CreateDocument} />
              <Route path={Routes.LIST_FILE_REPORT_INCOME} exact component={ListFileReportIncome} />
              <Route path={Routes.LIST_BUSINESS} exact component={ListBusinessGroup} />
              <Route path={Routes.CREATE_BUSINESS} exact component={CreateBusinessGroup} />
              <Route path={Routes.EDIT_BUSINESS} exact component={CreateBusinessGroup} />
            </>
          )}
        </Switch>
      </MainLayout>
      <Redirect path="*" to={Routes.HOME} />
    </HashRouter>
  );
};

export default AppRoutes;
