import { PencilIcon } from '@heroicons/react/solid';
import Button from '../../../../../components/Button';
import ListCard from '../../../../../components/ListCard';
import Table from '../../../../../components/Table';
import { cnpjMask } from '../../../../../helpers/cnpjMask';
import { translateStatus } from '../../../../_shared/helpers/FormatText';
import { BusinessGroup } from '../../../models/BusinessGroup';

interface Props {
  businessGroup?: BusinessGroup[];
  onClickEditBusinessGroup: (businessId: number) => void;
}

const BusinessGroupTable = ({ businessGroup, onClickEditBusinessGroup }: Props) => {
  return (
    <>
      <div className="sm:flex hidden">
        <Table
          columns={[
            {
              columnName: 'Nome',
              key: 'name'
            },
            {
              columnName: 'CNPJ',
              key: 'cnpj',
              transform: (value) => cnpjMask(value)
            },
            {
              columnName: 'Status',
              key: 'isActive',
              transform: (value) => translateStatus(value)
            },
            {
              columnName: '',
              key: '',
              component: (value, itemActive) => (
                <div className="flex justify-end space-x-2">
                  <PencilIcon
                    className="w-5 cursor-pointer text-teal-600"
                    onClick={() => onClickEditBusinessGroup(itemActive.id)}
                  />
                </div>
              )
            }
          ]}
          values={businessGroup}
        />
      </div>
      <div className="flex sm:hidden">
        <ListCard
          itemsLabel={[
            {
              label: 'Nome',
              key: 'name'
            },
            {
              label: 'CNPJ',
              key: 'cnpj',
              transform: (value) => cnpjMask(value)
            },
            {
              label: 'Status',
              key: 'isActive',
              transform: (value) => translateStatus(value)
            },
            {
              label: '',
              key: '',
              component: (value, itemActive) => (
                <div className="flex flex-col gap-2 sm:hidden w-full mt-[-16px]">
                  <Button
                    styles="w-full"
                    variant="primary"
                    onClick={() => onClickEditBusinessGroup(itemActive.id)}
                  >
                    Editar
                  </Button>
                </div>
              )
            }
          ]}
          values={businessGroup}
        />
      </div>
    </>
  );
};

export default BusinessGroupTable;
