import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useEffect } from 'react';

interface ConfigModalProps {
  isOpen: boolean;
  text: string;
}

const LoadingModal = ({ isOpen, text }: ConfigModalProps) => {
  const handleClose = () => {
    return false;
  };
  useEffect(() => {
    if (!isOpen) handleClose;
  }, [isOpen]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={handleClose}>
        <div className="min-h-screen px-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity" />
          </Transition.Child>

          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="w-full flex overflow-y-auto overflow-x-hidden fixed top-4 z-50 justify-center items-center h-full md:h-full inset-0">
              <div className="relative px-4 w-full max-w-2xl h-auto mx-3">
                <div className="relative bg-white rounded-lg w-full h-60 p-10 justify-center items-center">
                  <div className="w-full flex flex-col justify-center items-center">
                    <div className="w-full flex justify-center py-8">
                      <svg className="animate-spin h-[50px] w-[50px] mr-3" viewBox="25 25 50 50">
                        <circle
                          className="loader-path text-teal-600"
                          cx="50"
                          cy="50"
                          r="20"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="3"
                        />
                      </svg>
                    </div>
                    <div className="w-full flex justify-center mt-2">
                      <span className="flex text-base font-medium text-gray-500">{text}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default LoadingModal;
