import { ForgotPasswordTexts } from '../constants/ForgotPasswordTexts';

export const getForgotPasswordTextsForm = (stepId: number) => {
  const textsFound = ForgotPasswordTexts.find(
    (forgotPasswordText) => forgotPasswordText.id === stepId
  );
  if (textsFound) {
    return textsFound;
  } else {
    return ForgotPasswordTexts[0];
  }
};
