import { useField } from '@unform/core';
import React, { InputHTMLAttributes, useEffect, useRef } from 'react';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
  labelStyle?: string;
  inputStyle?: string;
  options: {
    id: string;
    value: string;
    label: string;
  }[];
}

const RadioForm: React.FC<Props> = ({ name, label, options, labelStyle, inputStyle, ...rest }) => {
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const { fieldName, registerField, defaultValue = '', error, clearError } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs: HTMLInputElement[]) => {
        return refs.find((ref) => ref.checked)?.value || '';
      },
      setValue: (refs: HTMLInputElement[], id: string) => {
        const inputRef = refs.find((ref) => ref.id === id);
        if (inputRef) inputRef.checked = true;
      },
      clearValue: (refs: HTMLInputElement[]) => {
        const inputRef = refs.find((ref) => ref.checked === true);
        if (inputRef) inputRef.checked = false;
      }
    });
  }, [defaultValue, fieldName, registerField]);

  return (
    <div className="flex flex-col mt-2 text-sm gap-0">
      <label
        className={labelStyle || `py-1 font-medium ${error ? ' text-red-500' : 'text-gray-500'}`}
      >
        {label}
      </label>
      <div className="flex flex-wrap items-center">
        {options.map((option, index) => (
          <label
            htmlFor={option.id}
            key={option.id}
            className={labelStyle || `py-2 mr-3 font-medium text-gray-500`}
          >
            <input
              ref={(ref) => ref && (inputRefs.current[index] = ref)}
              id={option.id}
              type="radio"
              name={name}
              defaultChecked={defaultValue.includes(option.id)}
              onChange={() => clearError()}
              value={option.value}
              className={
                inputStyle ||
                `p-2 mr-1 transition duration-200 cursor-pointer
              ${error ? 'accent-red-500' : 'accent-teal-600'}
            `
              }
              {...rest}
            />
            {option.label}
          </label>
        ))}
      </div>
      {error && <span className="text-red-500 text-xs ml-1">{error}</span>}
    </div>
  );
};

export default RadioForm;
