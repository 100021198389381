import queryString from 'query-string';
import api from '../../_shared/services/api';
import { Paginate, Result } from '../../_shared/types/api.types';
import { CreateUserDto } from '../dtos/CreateUserDto';
import { ListEmployeesDto } from '../dtos/ListEmployeeDto';
import { UpdateUserDto } from '../dtos/UpdateUserDto';
import { ProtectedUserClone, User } from '../models/User';

class EmployeeService {
  public async create(createUserDto: CreateUserDto): Promise<Result<User>> {
    const response = await api.post('/users', createUserDto);
    return response.data;
  }

  public async getEmployee(userId: string): Promise<Result<ProtectedUserClone>> {
    const response = await api.get(`/users/${userId}`);
    return response.data;
  }

  public async update(userId: string, updateUserDto: UpdateUserDto): Promise<Result<void>> {
    const response = await api.put(`/users/${userId}`, updateUserDto);
    return response.data;
  }

  public paginateCandidate = async ({
    ...listEmployeesDto
  }: ListEmployeesDto): Promise<Paginate<ProtectedUserClone>> => {
    const listEmployeesMapped = {
      page: listEmployeesDto.page,
      limit: listEmployeesDto.limit,
      query: listEmployeesDto.search,
      inForce: listEmployeesDto.employeesActive,
      'admittedDateRange[startDate]': listEmployeesDto.admittedDateRange?.startDate,
      'admittedDateRange[endDate]': listEmployeesDto.admittedDateRange?.endDate,
      'dismissalDateRange[startDate]': listEmployeesDto.dismissalDateRange?.startDate,
      'dismissalDateRange[endDate]': listEmployeesDto.dismissalDateRange?.endDate
    };
    const queryParams = queryString.stringify(listEmployeesMapped);
    const response = await api.get(`/users?${queryParams}`);
    return response.data.data;
  };
}

export default new EmployeeService();
