import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Pagination from '../../../../components/Paginate/Pagination';
import TitleCard from '../../../../components/TitleCard';
import { ProtectedUserClone } from '../../../employees/models/User';
import employeeService from '../../../employees/service/employee.service';
import { Paginate } from '../../../_shared/types/api.types';
import { PaginateUserHoleriteDto } from '../../dtos/PaginateUserHoleriteDto';
import { UserHolerite } from '../../models/UserHolerite';
import UserHoleriteService from '../../service/userHolerite.service';
import UserHoleriteTable from './components/UserHoleriteTable';

type ParamsProps = { id: string };

function ListUserHolerite() {
  const { id } = useParams<ParamsProps>();
  const [paginationActive, setPaginationActive] = useState<PaginateUserHoleriteDto>({});
  const [paginateUserHolerite, setPaginateUserHolerite] = useState<Paginate<UserHolerite>>();
  const [user, setUser] = useState<ProtectedUserClone | undefined>(undefined);

  const loadHolerites = async () => {
    const result = await UserHoleriteService.paginateUserHolerite({
      ...paginationActive,
      userId: id,
      limit: 10
    });
    if (id) {
      const { data } = await employeeService.getEmployee(id);
      setUser(data);
    }
    setPaginateUserHolerite(result);
  };

  const onChangePage = async (page: number) => {
    setPaginationActive((old: any) => ({ ...old, page }));
  };

  useEffect(() => {
    loadHolerites();
  }, [paginationActive]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-wrap justify-between">
        <TitleCard text={id ? `Holerites - ${user?.name}` : 'Holerites'} />
      </div>
      <div className="flex flex-col mt-6 gap-4">
        <UserHoleriteTable UserHolerite={paginateUserHolerite?.results} />
        <Pagination
          currentPageLength={paginateUserHolerite?.results.length}
          page={paginateUserHolerite?.currentPage}
          pageSize={paginateUserHolerite?.limit}
          onChangePage={onChangePage}
          totalItems={paginateUserHolerite?.totalItems}
        />
      </div>
    </div>
  );
}

export default ListUserHolerite;
