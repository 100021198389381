enum Routes {
  HOME = '/home',
  LOGIN = '/login',
  LIST_USER_HOLERITES = '/holerites',
  LIST_USER_HOLERITES_ADMIN = '/holerites/:id',
  LIST_HOLERITES_MANAGER = '/holerites-manager',
  CREATE_EMPLOYEE = '/employee',
  EDIT_EMPLOYEE = '/employee/:id',
  LIST_EMPLOYEES = '/list-employees',
  FIRST_ACCESS = '/first-access',
  FORGOT_PASSWORD = '/forgot-password',
  NEW_PASSWORD = '/new-password',
  CREATE_NOTICE = '/notice-board',
  EDIT_NOTICE = '/notice-board/:id',
  LIST_DOCUMENTS = '/list-documents',
  DIRECTORIES = '/directories',
  CREATE_DOCUMENT = '/create-document',
  EDIT_DOCUMENT = '/document/:id',
  LIST_FILE_REPORT_INCOME = '/files-reports-incomes',
  LIST_REPORT_INCOME = '/list-reports-incomes',
  LIST_BUSINESS = '/list-business-group',
  CREATE_BUSINESS = '/business-group',
  EDIT_BUSINESS = '/business-group/:id'
}

export default Routes;
