import { FileError } from '../../../helpers/fileError';
import api from '../../_shared/services/api';
import { Paginate, Result } from '../../_shared/types/api.types';
import { CreateDocumentDto } from '../dtos/Document/CreateDocumentDto';
import { ResultDocumentDto } from '../dtos/Document/ResultDocumentDto';
import { UpdateDocumentDto } from '../dtos/Document/UpdateDocumentDto';
import { Document } from '../models/Document';
export const API_IMAGE_URL = process.env.REACT_APP_API_URL + '/documents/images';

class DocumentService {
  public async newDocument(document: CreateDocumentDto): Promise<Result<ResultDocumentDto>> {
    const data = JSON.stringify(document);

    const formData = new FormData();
    formData.append('data', data);
    formData.append('files', document.file[0]);

    const response = await api({
      method: 'POST',
      url: '/documents',
      data: formData,
      maxContentLength: Infinity,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  }

  public async findAllDocument(): Promise<Paginate<Document>> {
    const response = await api.get('/documents');
    return response.data.data;
  }

  public async getDocument(documentId: string): Promise<Result<Document>> {
    const response = await api.get(`/documents/${documentId}`);
    return response.data;
  }

  public async updateDocument(
    documentId: string,
    updateDocumentDto: UpdateDocumentDto
  ): Promise<Result<void>> {
    const data = JSON.stringify(updateDocumentDto);

    const formData = new FormData();
    formData.append('data', data);
    if (updateDocumentDto.file) formData.append('files', updateDocumentDto.file[0]);

    const response = await api({
      method: 'PUT',
      url: `/documents/${documentId}`,
      data: formData,
      maxContentLength: Infinity,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  }

  public async deleteDocument(documentId: string): Promise<Result<void>> {
    const response = await api.delete(`documents/${documentId}`);
    return response.data;
  }

  getImageUrl(imageName: string): string {
    return `${API_IMAGE_URL}/${imageName}`;
  }

  public async getImageIfExists(imageName: string) {
    const url = this.getImageUrl(imageName);
    const response = await api.get(url, { responseType: 'blob' }).catch(() => {
      throw new FileError('Erro interno: Falha ao encontrar Documento');
    });
    return response.data;
  }
}

export default new DocumentService();
