import queryString from 'query-string';
import { FileError } from '../../../helpers/fileError';
import api from '../../_shared/services/api';
import { Paginate } from '../../_shared/types/api.types';
import { PaginateReportIncomeDto } from '../dto/PaginateReportIncomeDto';
import { ReportIncome } from '../models/ReportIncome';

class ReportIncomeService {
  public async paginateReportIncome({
    ...paginateReportIncome
  }: PaginateReportIncomeDto): Promise<Paginate<ReportIncome>> {
    const queryParams = queryString.stringify(paginateReportIncome);
    const response = await api.get(`/reportsIncomes?${queryParams}`);
    return response.data.data;
  }

  public async getReportIncome(reportIncomeId: number): Promise<Blob> {
    const response = await api
      .get(`/reportsIncomes/file/${reportIncomeId}`, {
        responseType: 'blob'
      })
      .catch(() => {
        throw new FileError('Erro interno: Falha ao encontrar Informe de Rendimento');
      });

    return response.data;
  }
}

export default new ReportIncomeService();
