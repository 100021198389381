import { Report } from './Report';

export class Validator {
  public reports: Report[];

  constructor() {
    this.reports = [];
  }

  public isRequired(value: string, name: string, message: string) {
    if (!value || value.length <= 0) {
      this.reports.push({ name, message });
    }
  }

  public isUndefined(value: any, name: string, message: string) {
    if (value === undefined) {
      this.reports.push({ name, message });
    }
  }

  public isGreaterThan(valuea: number, valueb: number, name: string, message: string) {
    if (valuea > valueb) {
      this.reports.push({ name, message });
    }
  }

  public isGreaterOrEqualsThan(valuea: number, valueb: number, name: string, message: string) {
    if (valuea >= valueb) {
      this.reports.push({ name, message });
    }
  }

  public isLessThan(valuea: number, valueb: number, name: string, message: string) {
    if (valuea < valueb) {
      this.reports.push({ name, message });
    }
  }

  public hasMinLen(value: string, min: number, name: string, message: string) {
    if (!value || value.length < min) {
      this.reports.push({ name, message });
    }
  }

  public hasMaxLen(value: any, max: number, name: string, message: string) {
    if (!value || value.length > max) {
      this.reports.push({ name, message });
    }
  }

  public isValidEmail(value: string, name: string, message: string) {
    const REGULAR_EXPRESSION = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    if (!REGULAR_EXPRESSION.test(value)) {
      this.reports.push({ name, message });
    }
  }

  public isValidUrl(value: string, name: string, message: string) {
    const REGULAR_EXPRESSION = /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/;
    if (!REGULAR_EXPRESSION.test(value)) {
      this.reports.push({ name, message });
    }
  }

  public isValidNumber(value: any, name: string, message: string) {
    if (isNaN(Number(value))) {
      this.reports.push({ name, message });
    }
  }

  public hasLowercase(value: string) {
    const REGULAR_EXPRESSION = /^(?=.*[a-z])/g;
    return REGULAR_EXPRESSION.test(value);
  }

  public hasUppercase(value: string) {
    const REGULAR_EXPRESSION = /^(?=.*[A-Z])/g;
    return REGULAR_EXPRESSION.test(value);
  }

  public hasNumber(value: string) {
    const REGULAR_EXPRESSION = /^(?=.*[0-9])/g;
    return REGULAR_EXPRESSION.test(value);
  }

  public hasSpecialCharacter(value: string) {
    const REGULAR_EXPRESSION = /^(?=.*[\W|])/g;
    return REGULAR_EXPRESSION.test(value);
  }

  public hasMinCharacter(value: string, min: number) {
    const newValue = value?.trim();
    return newValue && newValue.length >= min;
  }
}
