import queryString from 'query-string';
import api from '../../_shared/services/api';
import { Paginate, Result } from '../../_shared/types/api.types';
import { CreateBusinessGroupDto } from '../dtos/CreateBusinessGroupDto';
import { PaginateBusinessGroupDto } from '../dtos/PaginateBusinessGroupDto';
import { ResultBusinessGroupDto } from '../dtos/ResultBusinessGroupDto';
import { UpdateBusinessGroupDto } from '../dtos/UpdateBusinessGroupDto';
import { BusinessGroup } from '../models/BusinessGroup';

export const API_IMAGE_URL = process.env.REACT_APP_API_URL + '/businessGroup/images';

class BusinessGroupService {
  public async createBusinessGroup(
    businessGroup: CreateBusinessGroupDto
  ): Promise<Result<ResultBusinessGroupDto>> {
    const data = JSON.stringify(businessGroup);

    const formData = new FormData();
    formData.append('data', data);
    formData.append('files', businessGroup.file[0]);

    const response = await api({
      method: 'POST',
      url: '/businessGroup',
      data: formData,
      maxContentLength: Infinity,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  }

  public async paginateBusinessGroup({
    ...paginateBusinessGroupDto
  }: PaginateBusinessGroupDto): Promise<Paginate<BusinessGroup>> {
    const queryParams = queryString.stringify(paginateBusinessGroupDto);
    const response = await api.get(`/businessGroup?${queryParams}`);
    return response.data.data;
  }

  public async getBusinessGroupById(id: number | string): Promise<Result<BusinessGroup>> {
    const response = await api.get(`/businessGroup/${id}`);
    return response.data;
  }

  public async getBusinessGroup(): Promise<Paginate<BusinessGroup>> {
    const response = await api.get(`/businessGroup`);
    return response.data.data;
  }

  public getImageUrl(imageName: string): string {
    return `${API_IMAGE_URL}/${imageName}`;
  }

  public async updateBusinessGroup(
    id: number | string,
    updateBusinessGroupDto: UpdateBusinessGroupDto
  ): Promise<Result<void>> {
    const data = JSON.stringify(updateBusinessGroupDto);

    const formData = new FormData();
    formData.append('data', data);
    if (updateBusinessGroupDto.file) formData.append('files', updateBusinessGroupDto.file[0]);

    const response = await api({
      method: 'PUT',
      url: `/businessGroup/${id}`,
      data: formData,
      maxContentLength: Infinity,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  }
}

export default new BusinessGroupService();
