import { PencilIcon, TrashIcon } from '@heroicons/react/solid';
import React from 'react';
import { Contract } from '../../../../models/User';
import ItemCard from './ItemCard';

interface Props {
  contract: Contract;
  onClickEditItem: (item: any) => void;
  onClickRemoveItem: (item: any) => void;
}

const ContractCard: React.FC<Props> = ({ contract, onClickEditItem, onClickRemoveItem }) => {
  return (
    <div className="flex flex-col bg-gray-100 w-64 rounded">
      <div className="flex flex-col p-3">
        <div className="flex flex-row justify-between items-center">
          <span className="font-semibold text-sm text-teal-600">Contrato</span>
          <div className="flex flex-row gap-1">
            <div className="group">
              <div
                className="p-1 group-hover:bg-red-500 rounded transition duration-300 delay-75 ease-in-out cursor-pointer"
                onClick={onClickRemoveItem}
              >
                <TrashIcon className="h-5 w-5 group-hover:text-red-50 text-red-600 transition duration-300 delay-75 ease-in-out" />
              </div>
            </div>
            <div className="group">
              <div
                className="p-1 group-hover:bg-gray-400 rounded transition duration-300 delay-75 ease-in-out cursor-pointer"
                onClick={onClickEditItem}
              >
                <PencilIcon className="h-5 w-5 group-hover:text-gray-50 text-teal-600 transition duration-300 delay-75 ease-in-out " />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-2 mb-2">
          <ItemCard label={'Matrícula'} value={contract.registration.toString()} />
          <ItemCard label={'Data de admissão'} value={contract.admissionDate} />
          <ItemCard label={'Data de rescisão'} value={contract.terminationDate} />
        </div>
      </div>
    </div>
  );
};

export default ContractCard;
