export type TextForm = {
  id: number;
  title: string;
  message: string;
};

export const ForgotPasswordTexts: TextForm[] = [
  {
    id: 1,
    title: 'Solicitar redefinição de senha',
    message:
      'Para prosseguirmos com a redefinição de sua senha, informe os dados solicitados abaixo.'
  },
  {
    id: 2,
    title: 'Solicitação realizada com sucesso',
    message: 'Enviamos um email com o link para realizar a troca da senha.'
  },
  {
    id: 3,
    title: 'Definir nova senha',
    message: 'Defina uma nova senha.'
  }
];
