import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Button from '../../../../components/Button';
import ConfirmModal from '../../../../components/ModalComponents/ConfirmModal';
import LoadingModal from '../../../../components/ModalComponents/LoadingModal';
import Pagination from '../../../../components/Paginate/Pagination';
import TitleCard from '../../../../components/TitleCard';
import {
  getErrorMessage,
  manageApiErrorResponse
} from '../../../../helpers/handleApiErrorResponse';
import { Paginate } from '../../../_shared/types/api.types';
import { PaginateAggregratedHoleriteDto } from '../../dtos/PaginateAggregratedHoleriteDto';
import { AggregatedHolerite } from '../../models/AggregatedHolerite';
import AggregatedHoleriteService from '../../service/aggregatedHolerite.service';
import HoleritesAdmTable from './components/AggregatedHoleriteTable';
import NewHoleriteModal from './components/NewAggregatedHoleriteModal';

const ListAggregatedHolerite = () => {
  const [openNewHoleriteModal, setOpenNewHoleriteModal] = useState(false);
  const [paginationActive, setPaginationActive] = useState<PaginateAggregratedHoleriteDto>({});
  const [paginateAggregatedHolerite, setPaginateAggregratedHolerite] =
    useState<Paginate<AggregatedHolerite>>();
  const [openDeleteHoleritesModal, setOpenDeleteHoleritesModal] = useState(false);
  const [aggregateHoleriteIdActive, setAggregateHoleriteIdActive] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);

  const loadAggregratedHolerite = async () => {
    const result = await AggregatedHoleriteService.paginateAggregatedHolerite({
      ...paginationActive,
      limit: 10
    });

    setPaginateAggregratedHolerite(result);
  };

  const onChangePage = async (page: number) => {
    setPaginationActive((old) => ({ ...old, page }));
  };

  const handleClickNewHolerite = () => {
    setOpenNewHoleriteModal(true);
    // setContractError('');
  };

  const handleNewAggregatedHolerite = () => {
    loadAggregratedHolerite();
  };

  const handleCloseNewHolerite = () => {
    setOpenNewHoleriteModal(false);
    // setSelectedContractIndex(RESET_INDEX);
  };

  const handleClickDeleteHoleritesModal = (id: number) => {
    setAggregateHoleriteIdActive(id);
    setOpenDeleteHoleritesModal(true);
  };

  const handleConfirmDelete = async () => {
    setLoading(true);
    if (aggregateHoleriteIdActive) {
      try {
        const result = await AggregatedHoleriteService.removeAggregatedHolerite(
          aggregateHoleriteIdActive
        );
        toast.success(result.message);
        loadAggregratedHolerite();
        setOpenDeleteHoleritesModal(false);
      } catch (error) {
        handleErrors(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleErrors = (resultError: unknown) => {
    const resultErrorResponse = manageApiErrorResponse(resultError);
    const errorMessage = getErrorMessage(resultErrorResponse);

    toast.error(errorMessage);
  };

  useEffect(() => {
    loadAggregratedHolerite();
  }, [paginationActive]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-wrap justify-between">
        <TitleCard text="Holerites" />
        <div className="flex items-center sm:px-4 sm:py-5 py-4 w-full sm:w-auto justify-end">
          <Button variant="primary" type="button" onClick={handleClickNewHolerite}>
            Subir novo holerite
          </Button>
        </div>
      </div>
      <div className="flex flex-col gap-4 mt-6">
        <HoleritesAdmTable
          aggregatedHolerite={paginateAggregatedHolerite?.results}
          onClickDeleteHolerites={handleClickDeleteHoleritesModal}
        />
        <Pagination
          currentPageLength={paginateAggregatedHolerite?.results.length}
          page={paginateAggregatedHolerite?.currentPage}
          pageSize={paginateAggregatedHolerite?.limit}
          onChangePage={onChangePage}
          totalItems={paginateAggregatedHolerite?.totalItems}
        />
      </div>
      <NewHoleriteModal
        // contractToEdit={getSelectedContract()}
        isOpen={openNewHoleriteModal}
        onClose={handleCloseNewHolerite}
        onConfirm={handleNewAggregatedHolerite}
      />
      <ConfirmModal
        modalText="Deseja realmente excluir os holerites referentes a este arquivo?"
        isOpen={openDeleteHoleritesModal}
        onClose={() => setOpenDeleteHoleritesModal(false)}
        cancelButton={{
          buttonText: 'Cancelar',
          onClick: () => setOpenDeleteHoleritesModal(false)
        }}
        confirmButton={{
          buttonText: 'Confirmar',
          onClick: handleConfirmDelete
        }}
      />
      <LoadingModal
        isOpen={loading}
        text={'Aguarde enquanto validamos e processamos sua solicitação!'}
      />
    </div>
  );
};

export default ListAggregatedHolerite;
