import api from '../../_shared/services/api';
import { Result } from '../../_shared/types/api.types';
import { CreateDirectoryDto } from '../dtos/Directory/CreateDirectoryDto';
import { ResultDirectoryDto } from '../dtos/Directory/ResultDirectoryDto';
import { UpdateDirectoryDto } from '../dtos/Directory/UpdateDirectoryDto';
import { CreateSubDirectoryDto } from '../dtos/Subdirectory/CreateSubDirectoryDto';
import { ResultSubDirectoryDto } from '../dtos/Subdirectory/ResultSubDirectoryDto';
import { Directory } from '../models/Directory';

class DirectoryService {
  public async newDirectory(directory: CreateDirectoryDto): Promise<Result<ResultDirectoryDto>> {
    const response = await api.post('/directories', directory);
    return response.data;
  }

  public async update(
    directoryId: number,
    updateDirectoryDto: UpdateDirectoryDto
  ): Promise<Result<void>> {
    const response = await api.put(`/directories/${directoryId}`, updateDirectoryDto);
    return response.data;
  }

  public async getDirectory(directoryId: number): Promise<Result<Directory>> {
    const response = await api.get(`/directories/${directoryId}`);
    return response.data;
  }

  public async getDirectories(): Promise<Result<Directory[]>> {
    const response = await api.get(`/directories`);
    return response.data;
  }

  public async newSubDirectory(
    subDirectory: CreateSubDirectoryDto
  ): Promise<Result<ResultSubDirectoryDto>> {
    const response = await api.post('/subdirectory', subDirectory);
    return response.data;
  }
}

export default new DirectoryService();
