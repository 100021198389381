import { DotsVerticalIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import Dropdown from '../../../../../../../components/Dropdown';
import { Action } from '../../../../../../../components/Table';

interface Props {
  menuItems?: Action[];
  values?: any;
  className?: string;
}

const DropdownDisclosure = ({ menuItems, values, className }: Props) => {
  const [itemActive, setItemActive] = useState<any>();

  const handleClickItemMenu = (onClick: (currentSelected: any) => void) => {
    if (itemActive) onClick(itemActive);
  };

  const handleClickOpenMenu = (item: any) => {
    setItemActive(item);
  };

  return (
    <div className={className}>
      <Dropdown menuItens={menuItems} onClick={handleClickItemMenu} size="default">
        <DotsVerticalIcon
          className="w-4 h-4 cursor-pointer text-gray-700"
          onClick={() => handleClickOpenMenu(values.id)}
        />
      </Dropdown>
    </div>
  );
};

export default DropdownDisclosure;
