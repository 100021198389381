import { ArrowLeftIcon } from '@heroicons/react/outline';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import Button from '../../../../components/Button';
import DropzoneForm from '../../../../components/FormComponents/DropzoneForm';
import InputForm from '../../../../components/FormComponents/InputForm';
import SelectForm, { OptionSelect } from '../../../../components/FormComponents/SelectForm';
import ImagePreviewModal from '../../../../components/ImagePreviewModal';
import TitleCard from '../../../../components/TitleCard';
import {
  getErrorMessage,
  getFieldErrors,
  manageApiErrorResponse
} from '../../../../helpers/handleApiErrorResponse';
import Routes from '../../../_shared/constants/Routes.enum';
import { CreateDocumentDto } from '../../dtos/Document/CreateDocumentDto';
import { UpdateDocumentDto } from '../../dtos/Document/UpdateDocumentDto';
import { Directory } from '../../models/Directory';
import { FileDocument } from '../../models/Document';
import directoryService from '../../service/directory.service';
import documentService from '../../service/document.service';
import ImageDetail from './components/ImageDetail';

type ParamsProps = { id: string };

const CreateDocument = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [file, setFile] = useState<File>();
  const [image, setImage] = useState<FileDocument>();
  const { id } = useParams<ParamsProps>();
  const [optionsDirectory, setOptionsDirectory] = useState<OptionSelect[]>([]);

  const handleChangeSelectFile = (file: File) => {
    setImage(undefined);
    setFile(file);
  };

  const handleShowImage = () => {
    setIsOpen(true);
  };

  const handleRemoveImage = () => {
    setImage(undefined);
    setFile(undefined);
    formRef.current?.clearField('image');
  };

  const handleCloseImage = () => {
    setIsOpen(false);
  };

  const submitForm = async () => {
    const mainFormData = formRef.current?.getData();

    if (id) {
      const updateDocumentDto = {
        ...mainFormData
      } as UpdateDocumentDto;
      await handleUpdateDocument(updateDocumentDto);
    } else {
      const createDocumentDto = {
        ...mainFormData
      } as CreateDocumentDto;
      await handleCreateDocument(createDocumentDto);
    }
  };

  const handleUpdateDocument = async (updateDocumentDto: UpdateDocumentDto) => {
    try {
      const result = await documentService.updateDocument(id, updateDocumentDto);
      toast.success(result.message);
      history.push(Routes.LIST_DOCUMENTS);
    } catch (error) {
      handleErrors(error);
    }
  };

  const handleCreateDocument = async (createDocumentDto: CreateDocumentDto) => {
    try {
      const result = await documentService.newDocument(createDocumentDto);
      toast.success(result.message);
      history.push(Routes.LIST_DOCUMENTS);
    } catch (error) {
      handleErrors(error);
    }
  };

  const getOptionsDirectory = async () => {
    const directories = await directoryService.getDirectories();
    const flattenedOptions = flattenDirectories(directories?.data || []);
    setOptionsDirectory(flattenedOptions);
  };

  const flattenDirectories = (directories: Directory[]): OptionSelect[] => {
    const flattenedOptions: OptionSelect[] = [];
    const flatten = (directory: Directory) => {
      flattenedOptions.push({
        value: directory.id,
        label: directory.name
      });

      if (directory.subDirectories) {
        directory.subDirectories.forEach((subDirectory) => {
          flatten(subDirectory);
        });
      }
    };
    directories.forEach((directory) => {
      flatten(directory);
    });
    return flattenedOptions;
  };

  const handleErrors = (resultError: unknown) => {
    const fieldsErrors = getFieldErrors(resultError);
    formRef.current?.setErrors(fieldsErrors);
    const resultErrorResponse = manageApiErrorResponse(resultError);
    const error = getErrorMessage(resultErrorResponse);
    toast.error(error);
  };

  const loadDocument = async () => {
    try {
      const result = await documentService.getDocument(id);
      formRef.current?.setFieldValue('title', result.data.title);
      const directoryResult = result.data.directory;
      formRef.current?.setFieldValue('directoryId', {
        label: directoryResult.name,
        value: directoryResult.id
      });
      setImage(result.data.file);
    } catch (error) {}
  };

  const imageUrl = (imageName: string) => {
    return documentService.getImageUrl(imageName);
  };

  useEffect(() => {
    getOptionsDirectory();
    if (id) loadDocument();
  }, [id]);

  return (
    <div className="flex flex-col">
      <div className="flex sm:flex-row flex-col justify-between">
        <TitleCard text={`${id ? 'Editar' : 'Novo'} Documento`} />
        <div className="flex justify-end items-center sm:pr-6 gap-2">
          <button
            className="bg-gray-100 hover:bg-gray-200 rounded flex flex-row items-center text-center font-medium text-base cursor-pointer px-2 py-1"
            onClick={() => history.goBack()}
          >
            <ArrowLeftIcon className="w-3 h-3 mr-1" />
            <span className="font-medium text-base">Voltar</span>
          </button>
          <div className="items-center justify-end sm:px-1 py-3">
            <Button variant="primary" type="button" onClick={submitForm}>
              Salvar {id && 'alterações'}
            </Button>
          </div>
        </div>
      </div>
      <div className="flex flex-col sm:p-6 p-1">
        <Form ref={formRef} onSubmit={submitForm} className="flex flex-col gap-6">
          <div className="flex flex-col sm:flex-row gap-2 justify-between">
            <div className="sm:w-2/3 w-full">
              <InputForm name="title" label="Título" type="text" />
            </div>
            <div className="w-full sm:w-1/3 mt-1 sm:mt-2">
              <SelectForm name="directoryId" options={optionsDirectory} label="Setor" />
            </div>
          </div>
          {(file || image) && (
            <div className="flex flex-col">
              <ImageDetail
                showImage={handleShowImage}
                removeImage={handleRemoveImage}
                file={file}
                image={image}
              />
            </div>
          )}
          <div>
            <DropzoneForm
              onChange={handleChangeSelectFile}
              name="file"
              label={`${id ? 'atualizar' : 'selecionar'} um arquivo PNG, JPG ou PDF.`}
              acceptFiles={{
                'image/*': ['.png', '.jpg', '.jpeg'],
                'application/pdf': ['.pdf']
              }}
            />
          </div>
        </Form>
      </div>
      <ImagePreviewModal
        getUrlImage={imageUrl}
        file={file}
        image={image}
        isOpen={isOpen}
        onClose={handleCloseImage}
      />
    </div>
  );
};

export default CreateDocument;
