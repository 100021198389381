import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import Button from '../../../../components/Button';
import ConfirmModal from '../../../../components/ModalComponents/ConfirmModal';
import LoadingModal from '../../../../components/ModalComponents/LoadingModal';
import Pagination from '../../../../components/Paginate/Pagination';
import TitleCard from '../../../../components/TitleCard';
import {
  getErrorMessage,
  manageApiErrorResponse
} from '../../../../helpers/handleApiErrorResponse';
import { Paginate } from '../../../_shared/types/api.types';
import { PaginateFileReportIncomeDto } from '../../dto/PaginateFileReportIncomeDto';
import { FileReportIncome } from '../../models/FileReportIncome';
import fileReportIncomeService from '../../service/fileReportIncome.service';
import FileReportIncomeTable from './components/FileReportIncomeTable';
import UploadFileReportIncomeModal from './components/UploadFileReportIncomeModal';

const ListFileReportIncome = () => {
  const [openUploadFileReportIncomeModal, setOpenUploadFileReportIncomeModal] = useState(false);
  const [paginationActive, setPaginationActive] = useState<PaginateFileReportIncomeDto>({});
  const [paginateFileReportIncome, setPaginateFileReportIncome] =
    useState<Paginate<FileReportIncome>>();
  const [openDeleteReportIncome, setOpenDeleteReportIncome] = useState(false);
  const [fileReportIncomeIdActive, setFileReportIncomeIdActive] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);

  const loadFilesReportIncome = async () => {
    const result = await fileReportIncomeService.paginateFileReportIncome({
      ...paginationActive,
      limit: 10
    });
    setPaginateFileReportIncome(result);
  };

  const onChangePage = async (page: number) => {
    setPaginationActive((old) => ({ ...old, page }));
  };

  const handleClickUploadFileReportIncome = () => {
    setOpenUploadFileReportIncomeModal(true);
  };

  const handleUploadFileReportIncome = () => {
    loadFilesReportIncome();
    setOpenDeleteReportIncome(false);
  };

  const handleCloseUploadFileReportIncomeModal = () => {
    setOpenUploadFileReportIncomeModal(false);
  };

  const handleClickDeleteReportIncome = (id: number) => {
    setOpenDeleteReportIncome(true);
    setFileReportIncomeIdActive(id);
  };

  const handleConfirmDelete = async () => {
    setLoading(true);
    if (fileReportIncomeIdActive) {
      try {
        const result = await fileReportIncomeService.removeFileReportIncome(
          fileReportIncomeIdActive
        );
        toast.success(result.message);
        loadFilesReportIncome();
        setOpenDeleteReportIncome(false);
      } catch (error) {
        handleErrors(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleErrors = (resultError: unknown) => {
    const resultErrorResponse = manageApiErrorResponse(resultError);
    const errorMessage = getErrorMessage(resultErrorResponse);
    toast.error(errorMessage);
  };

  useEffect(() => {
    loadFilesReportIncome();
  }, [paginationActive]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-wrap justify-between">
        <TitleCard text="Informe de Rendimento" />
        <div className="flex items-center sm:px-4 sm:py-5 py-4 w-full sm:w-auto justify-end">
          <Button variant="primary" type="button" onClick={handleClickUploadFileReportIncome}>
            Subir novo IR
          </Button>
        </div>
      </div>
      <div className="flex flex-col mt-6 gap-4">
        <FileReportIncomeTable
          filesReportsIncomes={paginateFileReportIncome?.results}
          onClickDeleteReportIncome={handleClickDeleteReportIncome}
        />
        <Pagination
          currentPageLength={paginateFileReportIncome?.results.length}
          page={paginateFileReportIncome?.currentPage}
          pageSize={paginateFileReportIncome?.limit}
          onChangePage={onChangePage}
          totalItems={paginateFileReportIncome?.totalItems}
        />
      </div>
      <UploadFileReportIncomeModal
        isOpen={openUploadFileReportIncomeModal}
        onClose={handleCloseUploadFileReportIncomeModal}
        onConfirm={handleUploadFileReportIncome}
        isLoading={(value) => setLoading(value)}
      />
      <ConfirmModal
        modalText="Deseja realmente excluir o informe de rendimento referente a este arquivo?"
        isOpen={openDeleteReportIncome}
        onClose={() => setOpenDeleteReportIncome(false)}
        confirmButton={{ buttonText: 'Confirmar', onClick: handleConfirmDelete }}
        cancelButton={{ buttonText: 'Cancelar', onClick: () => setOpenDeleteReportIncome(false) }}
      />
      <LoadingModal
        isOpen={loading}
        text={'Aguarde enquanto validamos e processamos sua solicitação!'}
      />
    </div>
  );
};

export default ListFileReportIncome;
