import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { CheckmarkIcon, ErrorIcon } from 'react-hot-toast';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { Validator } from '../../../infra/Validator';

type Props = {
  passwordValue: string;
};

const PasswordAlert = ({ passwordValue }: Props) => {
  const validator = new Validator();

  return (
    <div>
      <a id="alert-password" className="cursor-pointer">
        <ExclamationCircleIcon className="h-5 w-5 text-gray-500 mx-2 ml-5" />
      </a>
      <Tooltip anchorId="alert-password" place="right" className="bg-gray-200">
        <div className="flex flex-col">
          <h1 className="text-teal-600 py-1 font-semibold">Segurança de Senhas</h1>
          <ul className="space-y-0.5 text-black font-medium">
            <li>
              <div className="flex flex-row">
                {validator.hasUppercase(passwordValue) ? (
                  <CheckmarkIcon className="mr-2" />
                ) : (
                  <ErrorIcon className="mr-2" />
                )}
                Letra Maiúscula
              </div>
            </li>
            <li>
              <div className="flex flex-row">
                {validator.hasLowercase(passwordValue) ? (
                  <CheckmarkIcon className="mr-2" />
                ) : (
                  <ErrorIcon className="mr-2" />
                )}
                Letra Minúscula
              </div>
            </li>
            <li>
              <div className="flex flex-row">
                {validator.hasSpecialCharacter(passwordValue) ? (
                  <CheckmarkIcon className="mr-2" />
                ) : (
                  <ErrorIcon className="mr-2" />
                )}
                Caractere Especial
              </div>
            </li>
            <li>
              <div className="flex flex-row">
                {validator.hasNumber(passwordValue) ? (
                  <CheckmarkIcon className="mr-2" />
                ) : (
                  <ErrorIcon className="mr-2" />
                )}
                Números
              </div>
            </li>
            <li>
              <div className="flex flex-row">
                {validator.hasMinCharacter(passwordValue, 8) ? (
                  <CheckmarkIcon className="mr-2" />
                ) : (
                  <ErrorIcon className="mr-2" />
                )}
                Mínimo 8 Caracteres
              </div>
            </li>
          </ul>
        </div>
      </Tooltip>
    </div>
  );
};

export default PasswordAlert;
