import { format, isValid, parse } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

export const convertToDate = (date: string) => {
  if (!date) return;
  const dateConverted = parse(date, 'dd/MM/yyyy', new Date());
  if (isValid(dateConverted)) {
    return dateConverted;
  } else {
    return '';
  }
};

export const convertDateToString = (date?: Date) => {
  if (date) {
    return format(date, 'dd/MM/yyyy', { locale: ptBR });
  } else {
    return '--/--/----';
  }
};
