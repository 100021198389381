import queryString from 'query-string';
import api from '../../_shared/services/api';
import { Paginate, Result } from '../../_shared/types/api.types';
import { PaginateFileReportIncomeDto } from '../dto/PaginateFileReportIncomeDto';
import { UploadFileReportIncomeDto } from '../dto/UploadFileReportIncomeDto';
import { FileReportIncome } from '../models/FileReportIncome';

class FileReportIncomeService {
  public async create(dto: UploadFileReportIncomeDto): Promise<Result<FileReportIncome>> {
    const data = JSON.stringify(dto);

    const formData = new FormData();
    formData.append('data', data);
    formData.append('files', dto.file[0]);

    const response = await api({
      method: 'POST',
      url: '/reportsIncomes/file-upload',
      data: formData,
      maxContentLength: Infinity,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    return response.data;
  }

  public async removeFileReportIncome(id: number): Promise<Result<void>> {
    const response = await api.delete(`/reportsIncome/file/${id}`);
    return response.data;
  }

  public async paginateFileReportIncome({
    ...dto
  }: PaginateFileReportIncomeDto): Promise<Paginate<FileReportIncome>> {
    const queryParams = queryString.stringify(dto);

    const response = await api.get(`/reportsIncomes/file-upload?${queryParams}`);

    return response.data.data;
  }
}

export default new FileReportIncomeService();
