import queryString from 'query-string';
import api from '../../_shared/services/api';
import { Paginate, Result } from '../../_shared/types/api.types';
import { CreateUserHoleriteDto } from '../dtos/CreateUserHoleriteDto';
import { PaginateUserHoleriteDto } from '../dtos/PaginateUserHoleriteDto';
import { ResultUserHoleriteDto } from '../dtos/ResultUserHoleriteDto';
import { UserHolerite } from '../models/UserHolerite';
import { FileError } from '../../../helpers/fileError';

class UserHoleriteService {
  public async newUserHolerite(
    userholerite: CreateUserHoleriteDto
  ): Promise<Result<ResultUserHoleriteDto>> {
    const data = JSON.stringify(userholerite);
    const formData = new FormData();
    formData.append('data', data);

    const response = await api({
      method: 'POST',
      url: '/holerites',
      data: formData,
      maxContentLength: Infinity,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  }

  public async paginateUserHolerite({
    ...paginateUserHolerite
  }: PaginateUserHoleriteDto): Promise<Paginate<UserHolerite>> {
    const queryParams = queryString.stringify(paginateUserHolerite);
    const response = await api.get(`/holerites?${queryParams}`);
    return response.data.data;
  }

  public async getPdfUrl(holeriteId: number) {
    const response = await api
      .get(`/holerites/${holeriteId}`, { responseType: 'blob' })
      .catch(() => {
        throw new FileError('Erro interno: Falha ao encontrar Holerite');
      });
    return response.data;
  }
}

export default new UserHoleriteService();
