import { convertToDate } from '../../../helpers/convertToDate';
import { Notifiable } from '../../../infra/Notifiable';
import { Report } from '../../../infra/Report';
import { Validator } from '../../../infra/Validator';
import { Contract } from '../models/User';

export class CreateContract extends Notifiable {
  private dto: Contract;
  private validator: Validator;

  constructor(dto: Contract) {
    super();
    this.dto = dto;
    this.validator = new Validator();
  }

  validate(): boolean {
    this.validateRegistration();
    this.validateAdmissionDate();
    this.validateTerminationDate();

    this.addReports(this.validator.reports);
    return this.isValid();
  }

  private validateRegistration() {
    const { registration } = this.dto;
    this.validator.isRequired(
      registration.toString(),
      'registration',
      'O campo Registro é de preenchimento obrigatório'
    );
  }

  private validateAdmissionDate() {
    const { admissionDate } = this.dto;
    if (!admissionDate || admissionDate === '--/--/----') {
      this.addReport(new Report('admissionDate', 'A data de admissão é obrigatória'));
    }
  }

  private validateTerminationDate() {
    const { terminationDate } = this.dto;
    const terminationDateDateConverted = convertToDate(terminationDate);
    if (terminationDateDateConverted) {
      const isInFuture = new Date(terminationDateDateConverted).getTime() > new Date().getTime();
      if (isInFuture)
        this.addReport(new Report('terminationDate', 'Data de rescisão não pode ser no futuro'));
    }
  }
}
