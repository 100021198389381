import { Dialog, Transition } from '@headlessui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Fragment, useEffect, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import Button from '../../../../../../components/Button';
import InputForm from '../../../../../../components/FormComponents/InputForm';
import Switch from '../../../../../../components/Switch/Switch';
import {
  getErrorMessage,
  getFieldErrors,
  manageApiErrorResponse
} from '../../../../../../helpers/handleApiErrorResponse';
import { CreateSubDirectoryDto } from '../../../../dtos/Subdirectory/CreateSubDirectoryDto';
import { Directory } from '../../../../models/Directory';
import { SubDirectory } from '../../../../models/SubDirectory';
import directoryService from '../../../../service/directory.service';

interface ConfigModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  modalTitle: string;
  subdirectory?: SubDirectory;
  directory?: Directory;
  parentId?: number;
}

const CreateSubdirectoryModal = ({
  isOpen,
  modalTitle,
  onClose,
  onConfirm,
  subdirectory,
  directory,
  parentId
}: ConfigModalProps) => {
  const formRef = useRef<FormHandles>(null);
  const [isActive, setIsActive] = useState(false);

  const createSubdirectory = async () => {
    const mainFormData = formRef.current?.getData();
    const newSubdirectoryToCreate = { ...mainFormData, isActive: isActive, parentId: parentId };
    const newSubdirectoryDto = {
      ...newSubdirectoryToCreate
    } as CreateSubDirectoryDto;
    try {
      const result = await directoryService.newSubDirectory(newSubdirectoryDto);
      toast.success(result.message);
    } catch (error) {
      handleError(error);
    }
    onClose();
    onConfirm();
    clearForm();
  };

  const handleError = (resultError: unknown) => {
    const fieldsErrors = getFieldErrors(resultError);
    formRef.current?.setErrors(fieldsErrors);
    const resultErrorResponse = manageApiErrorResponse(resultError);
    const errorMessage = getErrorMessage(resultErrorResponse);
    toast.error(errorMessage);
  };

  const handleSubdirectoriesActive = (value: boolean) => {
    if (value) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
    setIsActive(value);
  };

  const handleCancel = () => {
    onClose();
    clearForm();
  };

  const clearForm = () => {
    formRef.current?.reset();
  };

  useEffect(() => {
    if (subdirectory) setIsActive(subdirectory?.isActive);
  }, [subdirectory]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={onClose}>
        <div className="min-h-screen px-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="flex overflow-y-auto overflow-x-hidden fixed top-4 z-50 justify-center items-center h-full md:h-full inset-0">
              <div className="relative px-4 w-full max-w-2xl h-auto mx-3">
                <Form
                  ref={formRef}
                  initialData={subdirectory}
                  onSubmit={createSubdirectory}
                  className="flex justify-center"
                >
                  <div className="relative bg-white rounded-lg shadow w-full sm:w-3/5 ">
                    <div className="flex flex-col items-start p-4 rounded-t border-b">
                      <span className="text-xl font-semibold text-gray-title lg:text-2xl">
                        {modalTitle}
                      </span>
                    </div>
                    <div className="p-6 space-y-3">
                      <span className="cursor-pointer">
                        <a className="font-bold">Diretório: </a>
                        {directory?.name}
                      </span>
                      <InputForm type="string" name="name" label="Nome" />
                      <div className="flex items-center py-2 text-sm font-medium text-gray-500">
                        <Switch
                          modalText="Ativo:"
                          onChange={handleSubdirectoriesActive}
                          value={isActive}
                        />
                      </div>
                    </div>
                    <div className="flex items-center justify-end p-6 space-x-3 rounded-b border-t border-gray-200">
                      <Button variant="default" type="button" onClick={handleCancel}>
                        Cancelar
                      </Button>
                      <Button variant="primary" type="button" onClick={createSubdirectory}>
                        Salvar
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default CreateSubdirectoryModal;
