import { Dialog, Transition } from '@headlessui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Fragment, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import Button from '../../../../../../components/Button';
import DropzoneForm from '../../../../../../components/FormComponents/DropzoneForm';
import SelectForm, { OptionSelect } from '../../../../../../components/FormComponents/SelectForm';
import {
  getErrorMessage,
  getFieldErrors,
  manageApiErrorResponse
} from '../../../../../../helpers/handleApiErrorResponse';
import FileDetail from '../../../../../aggregatedHolerite/pages/ListAggregatedHolerite/components/FileDetail';
import { UploadFileReportIncomeDto } from '../../../../dto/UploadFileReportIncomeDto';
import fileReportIncomeService from '../../../../service/fileReportIncome.service';

interface ConfigModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isLoading: (value: boolean) => void;
}

const UploadFileReportIncomeModal = ({
  isOpen,
  onClose,
  onConfirm,
  isLoading
}: ConfigModalProps) => {
  const formRef = useRef<FormHandles>(null);
  const [file, setFile] = useState<File>();
  const [yearOptions, setYearOptions] = useState<OptionSelect[]>([]);

  const handleUploadFileReportIncome = async () => {
    isLoading(true);
    try {
      const mainFormData = formRef.current?.getData();
      const uploadFileReportIncomeDto = {
        ...mainFormData
      } as UploadFileReportIncomeDto;

      const result = await fileReportIncomeService.create(uploadFileReportIncomeDto);

      toast.success(result.message);
      onClose();
      onConfirm();
      clearForm();
    } catch (error) {
      handleErrors(error);
    } finally {
      isLoading(false);
    }
  };

  const handleErrors = (resultError: unknown) => {
    const fieldsErrors = getFieldErrors(resultError);
    formRef.current?.setErrors(fieldsErrors);
    const resultErrorResponse = manageApiErrorResponse(resultError);
    const errorMessage = getErrorMessage(resultErrorResponse);

    toast.error(errorMessage);
  };

  const handleCancel = () => {
    onClose();
    clearForm();
  };

  const clearForm = () => {
    formRef.current?.reset();
  };

  const handleFile = (file: File) => {
    setFile(file);
  };

  const handleRemoveFile = () => {
    setFile(undefined);
    formRef.current?.clearField('holerite_file');
  };

  const getYearOptions = () => {
    const date = new Date();
    const atualYear = date.getFullYear();
    const passedYears = atualYear - 2007;
    const years = [];
    for (let index = 0; index < passedYears; index++) {
      years.push({
        value: atualYear - index,
        label: `${atualYear - index}`
      });
    }
    setYearOptions(years);
  };

  useEffect(() => {
    getYearOptions();
  }, []);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={onClose}>
        <div className="min-h-screen px-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="flex overflow-y-auto overflow-x-hidden fixed top-4 z-50 justify-center items-center h-full md:h-full inset-0">
              <div className="relative px-4 w-full max-w-2xl h-auto mx-3">
                <Form
                  ref={formRef}
                  onSubmit={handleUploadFileReportIncome}
                  className="flex justify-center"
                >
                  <div className="relative bg-white rounded-lg shadow w-full sm:w-3/5 ">
                    <div className="flex flex-col items-start p-4 rounded-t border-b gap-2">
                      <span className="text-xl font-semibold text-gray-title lg:text-2xl">
                        Novo envio
                      </span>
                      <span className="text-gray-title">
                        Enviar novo arquivo de informe de rendimento para todos os usuários
                      </span>
                    </div>
                    <div className="p-6 space-y-3">
                      <SelectForm
                        name="referenceYear"
                        options={yearOptions}
                        label="Ano de Referência"
                      />
                      {file && (
                        <div className="flex flex-col">
                          <FileDetail removeImage={handleRemoveFile} file={file} />
                        </div>
                      )}
                      <DropzoneForm
                        name="file"
                        onChange={handleFile}
                        label="selecionar um arquivo pdf."
                        acceptFiles={{ 'application/pdf': ['.pdf'] }}
                      />
                    </div>
                    <div className="flex items-center justify-end p-6 space-x-3 rounded-b border-t border-gray-200">
                      <Button variant="default" type="button" onClick={handleCancel}>
                        Cancelar
                      </Button>
                      <Button
                        variant="primary"
                        type="button"
                        onClick={handleUploadFileReportIncome}
                      >
                        Enviar
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default UploadFileReportIncomeModal;
