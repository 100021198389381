import Button from '../../../../../../components/Button';
import ListCard from '../../../../../../components/ListCard';
import Table, { Action } from '../../../../../../components/Table';
import { translateBoolean, translateStatus } from '../../../../../_shared/helpers/FormatText';
import { Directory } from '../../../../models/Directory';

interface Props {
  directory?: Directory[];
  onClickEditDirectory: (directoryId: number) => void;
  onClickAgroupDirectory: () => void;
}

const DirectoryTable = ({ directory, onClickEditDirectory, onClickAgroupDirectory }: Props) => {
  const actions: Action[] = [
    { label: 'Editar', onClick: onClickEditDirectory },
    { label: 'Agrupar', onClick: onClickAgroupDirectory }
  ];
  return (
    <>
      <div className="sm:flex hidden">
        <Table
          columns={[
            {
              columnName: 'Nome',
              key: 'name'
            },
            {
              columnName: 'Status',
              key: 'isActive',
              transform: (value) => translateStatus(value)
            }
          ]}
          values={directory}
          menuItems={actions}
        />
      </div>
      <div className="flex sm:hidden">
        <ListCard
          itemsLabel={[
            {
              label: 'Nome',
              key: 'name'
            },
            {
              label: 'Status',
              key: 'isActive',
              transform: (value) => translateBoolean(value)
            },
            {
              label: '',
              key: '',
              component: (value, itemActive) => (
                <div className="flex flex-col gap-2 sm:hidden w-full mt-[-16px]">
                  <Button
                    styles="w-full"
                    variant="primary"
                    onClick={() => onClickEditDirectory(itemActive.id)}
                  >
                    Editar
                  </Button>
                  <Button styles="w-full" variant="default" onClick={onClickAgroupDirectory}>
                    Agrupar
                  </Button>
                </div>
              )
            }
          ]}
          values={directory}
          // subvalues={subdirectory}
        />
      </div>
    </>
  );
};

export default DirectoryTable;
