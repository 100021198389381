import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useRef } from 'react';
import toast from 'react-hot-toast';
import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as CollaboratorsPhoto } from '../../../../assets/collaborators.svg';
import Button from '../../../../components/Button';
import InputForm from '../../../../components/FormComponents/InputForm';
import InputMaskForm from '../../../../components/FormComponents/InputMaskForm';
import {
  getFieldErrors,
  manageApiErrorMessages,
  manageApiErrorResponse
} from '../../../../helpers/handleApiErrorResponse';
import Routes from '../../../_shared/constants/Routes.enum';
import { LoginCredentials, useAuth } from '../../contexts/AuthProvider';

const Login = () => {
  const { signIn } = useAuth();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);

  const handleLogin = async () => {
    try {
      const singInCredentials = formRef.current?.getData() as LoginCredentials;
      await signIn(singInCredentials);
    } catch (resultError) {
      const fieldsErrors = getFieldErrors(resultError);
      formRef.current?.setErrors(fieldsErrors);
      const resultErrorResponse = manageApiErrorResponse(resultError);
      const resultErrors = manageApiErrorMessages(resultErrorResponse);
      resultErrors.forEach((resultError) => toast.error(resultError));
    }
  };

  return (
    <div className="flex flex-col items-center w-full py-8">
      <div className="flex items-start w-full">
        <h1 className="text-3xl font-semibold text-teal-600 mb-6">Área do colaborador</h1>
      </div>
      <div className="flex flex-col w-full md:flex-row gap-8 md:gap-2 lg:gap-16">
        <div className="lg:w-1/2 md:max-w-xl items-center flex flex-col md:gap-24 gap-10 mt-8">
          <p className="text-lg text-stone-700">
            Bem-vindo a area do colaborador INOVVATI. Estamos inovando o sistema para melhor atender
            você.
          </p>

          <CollaboratorsPhoto className="lg:w-[460px] sm:w-96 w-60 h-auto mx-auto" />
        </div>
        <div className="lg:w-1/2 w-full flex flex-col gap-4">
          <div className="rounded border border-teal-500 border-dashed p-4 ">
            <p className="text-xl font-medium mb-4">Primeiro acesso?</p>
            <p className="text-md mb-5">
              Caso você seja um colaborador e ainda não criou seu usuário
            </p>

            <Button variant="primary" onClick={() => history.push(Routes.FIRST_ACCESS)}>
              Cadastre-se
            </Button>
          </div>

          <div className="rounded border border-teal-500 border-dashed p-4">
            <Form ref={formRef} onSubmit={handleLogin}>
              <p className="text-xl font-medium mb-5">Entrar</p>
              <div className="mb-4 ">
                <InputMaskForm name="cpf" maskChar={null} inputMask="999.999.999-99" label="CPF" />
                <div className="mb-4"></div>
                <InputForm name="password" type="password" label="Senha" placeholder="Senha" />
              </div>
              <div className="flex justify-between items-center mb-4 flex-wrap">
                <Link
                  to={Routes.FORGOT_PASSWORD}
                  className="text-teal-600 self flex flex-row items-center hover:opacity-75"
                >
                  Redefinir senha
                </Link>
                <Button variant="primary">Acessar</Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
