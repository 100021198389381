import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../../../components/Button';
import Pagination from '../../../../components/Paginate/Pagination';
import TitleCard from '../../../../components/TitleCard';
import Routes from '../../../_shared/constants/Routes.enum';
import { Paginate } from '../../../_shared/types/api.types';
import { PaginateBusinessGroupDto } from '../../dtos/PaginateBusinessGroupDto';
import { BusinessGroup } from '../../models/BusinessGroup';
import businessGroupService from '../../service/businessGroup.service';
import BusinessGroupTable from './components/BusinessGroupTable';

const ListBusinessGroup = () => {
  const [paginateBusinessGroup, setPaginateBusinessGroup] = useState<Paginate<BusinessGroup>>();
  const [paginationActive, setPaginationActive] = useState<PaginateBusinessGroupDto>({});
  const history = useHistory();

  const handleClickEditBusinessGroup = (id: number) => {
    history.push(`/business-group/${id}`);
  };

  const onChangePage = async (page: number) => {
    setPaginationActive((old) => ({ ...old, page }));
  };

  const loadBusinessGroup = async () => {
    const result = await businessGroupService.paginateBusinessGroup({
      ...paginationActive,
      limit: 10
    });
    setPaginateBusinessGroup(result);
  };

  useEffect(() => {
    loadBusinessGroup();
  }, [paginationActive]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-wrap justify-between">
        <TitleCard text="Grupo Econômico" />
        <div className="flex items-center sm:px-4 sm:py-5 py-4 w-full sm:w-auto justify-end">
          <Button variant="primary" onClick={() => history.push(Routes.CREATE_BUSINESS)}>
            Novo CNPJ
          </Button>
        </div>
      </div>
      <div className="mt-6">
        <BusinessGroupTable
          businessGroup={paginateBusinessGroup?.results}
          onClickEditBusinessGroup={handleClickEditBusinessGroup}
        />
      </div>

      <div className="mt-4">
        <Pagination
          currentPage={paginateBusinessGroup?.results.length}
          currentPageLength={paginateBusinessGroup?.results.length}
          page={paginateBusinessGroup?.currentPage}
          pageSize={paginateBusinessGroup?.limit}
          onChangePage={onChangePage}
          totalItems={paginateBusinessGroup?.totalItems}
        />
      </div>
    </div>
  );
};

export default ListBusinessGroup;
