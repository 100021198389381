import { useField } from '@unform/core';
import {
  addMonths,
  addYears,
  format,
  getDay,
  getDaysInMonth,
  isEqual,
  subMonths,
  subYears
} from 'date-fns';
import { ptBR } from 'date-fns/locale';
import React, { useEffect, useRef, useState } from 'react';
import ReactInputMask from 'react-input-mask';
import { cleanMask } from '../../helpers/cleanMask';
import { convertToDate } from '../../helpers/convertToDate';

type DatepickerType = 'date' | 'month' | 'year';

interface Props {
  name: string;
  label?: string;
  disabled?: boolean;
}

type InputProps = JSX.IntrinsicElements['input'] & Props;

const DatepickerForm = ({ name, label, disabled, ...rest }: InputProps) => {
  const DAYS = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];
  const [dayCount, setDayCount] = useState<Array<number>>([]);
  const [blankDays, setBlankDays] = useState<Array<number>>([]);
  const [showDatepicker, setShowDatepicker] = useState(false);
  const [datepickerHeaderDate, setDatepickerHeaderDate] = useState(new Date());
  const [year, setYear] = useState(new Date().getFullYear());
  const [type, setType] = useState<DatepickerType>('date');

  const datepickerRef = useRef<any>();
  const { fieldName, registerField, defaultValue, error, clearError } = useField(name);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [dateString, setDateString] = useState(defaultValue || null);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleChange = (event: any) => {
    clearError();
    const newValue = cleanMask(event.target.value);
    setDateString(newValue);
  };

  const decrement = () => {
    switch (type) {
      case 'date':
        setDatepickerHeaderDate((prev) => subMonths(prev, 1));
        break;
      case 'month':
        setDatepickerHeaderDate((prev) => subYears(prev, 1));
        setYear(year - 1);
        break;
      case 'year':
        setDatepickerHeaderDate((prev) => subYears(prev, 10));
        setYear(year - 10);
        break;
    }
  };

  const increment = () => {
    switch (type) {
      case 'date':
        setDatepickerHeaderDate((prev) => addMonths(prev, 1));
        break;
      case 'month':
        setDatepickerHeaderDate((prev) => addYears(prev, 1));
        setYear(year + 1);
        break;
      case 'year':
        setDatepickerHeaderDate((prev) => addYears(prev, 10));
        setYear(year + 10);
        break;
    }
  };

  const isToday = (date: number) =>
    isEqual(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), date), selectedDate);

  const setDateValue = (date: number) => () => {
    const newDate = new Date(
      datepickerHeaderDate.getFullYear(),
      datepickerHeaderDate.getMonth(),
      date
    );
    setSelectedDate(newDate);
    setDateString(format(newDate, 'dd/MM/yyyy', { locale: ptBR }));
    setShowDatepicker(false);
  };

  const getDayCount = (date: Date) => {
    const daysInMonth = getDaysInMonth(date);

    // find where to start calendar day of week
    const dayOfWeek = getDay(new Date(date.getFullYear(), date.getMonth(), 1));
    const blankdaysArray = [];
    for (let i = 1; i <= dayOfWeek; i++) {
      blankdaysArray.push(i);
    }

    const daysArray = [];
    for (let i = 1; i <= daysInMonth; i++) {
      daysArray.push(i);
    }

    setBlankDays(blankdaysArray);
    setDayCount(daysArray);
  };

  const isSelectedMonth = (month: number) =>
    isEqual(new Date(selectedDate.getFullYear(), month, selectedDate.getDate()), selectedDate);

  const setMonthValue = (month: number) => () => {
    setDatepickerHeaderDate(
      new Date(datepickerHeaderDate.getFullYear(), month, datepickerHeaderDate.getDate())
    );
    setType('date');
  };

  const setYearValue = (year: number) => () => {
    setDatepickerHeaderDate(
      new Date(year, datepickerHeaderDate.getMonth(), datepickerHeaderDate.getDate())
    );
    setYear(year);
    setType('month');
  };

  const toggleDatepicker = () => {
    clearError();
    setShowDatepicker((prev) => !prev);
  };

  const showMonthPicker = () => setType('month');

  const showYearPicker = (number: number) => {
    if (type === 'year') {
      setType('month');
    } else {
      setType('year');
    }
    setYear(number);
  };

  const mountPreviousYear = -12;

  const getPreviousYear = (number: number) => {
    const currentYear = year;
    const previousYear = mountPreviousYear + number;

    return currentYear + previousYear;
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datepickerRef.current,
      clearValue: () => {
        setDateString('');
        setSelectedDate(new Date());
        setDatepickerHeaderDate(new Date());
        clearError();
      },
      setValue: (e, v) => {
        setDateString(v);
      },
      getValue: () => {
        return datepickerRef.current.value;
      }
    });
  }, [fieldName, registerField, dateString, setDateString]);

  useEffect(() => {
    const handleHideDropdown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setShowDatepicker(false);
      }
    };

    const handleClickedOutside = (e: any) => {
      if (showDatepicker && wrapperRef.current && !wrapperRef.current.contains(e.target)) {
        setShowDatepicker(false);
      }
    };
    document.addEventListener('keydown', handleHideDropdown);
    document.addEventListener('mousedown', handleClickedOutside);

    return () => {
      document.removeEventListener('keydown', handleHideDropdown);
      document.removeEventListener('mousedown', handleClickedOutside);
    };
  }, [showDatepicker]);

  useEffect(() => {
    getDayCount(datepickerHeaderDate);
  }, [datepickerHeaderDate]);

  useEffect(() => {
    const convertedDate = convertToDate(dateString);
    if (convertedDate) setSelectedDate(convertedDate);
  }, [dateString]);
  return (
    <div className="antialiased flex flex-col" ref={wrapperRef}>
      <div>
        <div className="flex flex-col mt-2 text-sm">
          <div>
            <label
              htmlFor={name}
              className={`w-full py-1 font-medium ${error ? ' text-red-500' : 'text-gray-500'}`}
            >
              {label}
            </label>
            <div className="relative">
              <div className="flex items-center">
                <input type="hidden" name={name} />
                <ReactInputMask
                  id={name}
                  name={fieldName}
                  mask="99/99/9999"
                  ref={datepickerRef}
                  defaultValue={defaultValue}
                  placeholder="Selecionar data"
                  onChange={handleChange}
                  alwaysShowMask={true}
                  value={dateString}
                  disabled={disabled}
                  className={`w-full pl-4 pr-10 py-2 mt-1 rounded border focus:ring-1 p-2 focus:outline-none font-medium ${
                    error
                      ? 'border-red-500 focus:border-red-500 focus:ring-red-500'
                      : 'text-gray-500 border-gray-200 focus:border-teal-600 focus:ring-teal-600'
                  }`}
                />
                <div
                  className={`${
                    disabled ? 'cursor-default' : 'cursor-pointer'
                  } absolute top-0 right-0 px-3 py-2 mt-[3px]`}
                  onClick={disabled ? undefined : toggleDatepicker}
                >
                  <svg
                    className="h-6 w-6 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                    />
                  </svg>
                </div>
              </div>
              {error && <span className="text-red-500 text-xs mt-1 ml-1">{error}</span>}
              {showDatepicker && (
                <div className="bg-white mt-12 rounded-lg shadow p-4 absolute top-0 left-0 z-20 w-60 sm:w-[272px]">
                  <div className="flex justify-between items-center mb-2">
                    <div>
                      <button
                        type="button"
                        className="transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 rounded-full"
                        onClick={decrement}
                      >
                        {type === 'year' ? (
                          <div>
                            <svg
                              className="h-6 w-6 text-gray-500 inline-flex"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M15 19l-7-7 7-7"
                              />
                            </svg>
                            <span className="font-semibold text-xs text-gray-500 pr-2">-10</span>
                          </div>
                        ) : (
                          <svg
                            className="h-6 w-6 text-gray-500 inline-flex"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M15 19l-7-7 7-7"
                            />
                          </svg>
                        )}
                      </button>
                    </div>
                    {type === 'date' && (
                      <div
                        onClick={showMonthPicker}
                        className="flex-grow p-1 text-lg font-bold text-gray-600 cursor-pointer hover:bg-gray-200 rounded-lg"
                      >
                        <p className="text-center capitalize">
                          {format(datepickerHeaderDate, 'MMMM', { locale: ptBR })}
                        </p>
                      </div>
                    )}
                    <div
                      onClick={() => showYearPicker(year)}
                      className={`flex-grow p-1 text-lg font-bold  cursor-pointer hover:bg-gray-200 rounded-lg ${
                        type === 'year'
                          ? 'bg-teal-400 text-white hover:bg-teal-400'
                          : 'text-gray-600 hover:bg-teal-100'
                      }`}
                    >
                      <p className="text-center">{format(datepickerHeaderDate, 'yyyy')}</p>
                    </div>
                    <div>
                      <button
                        type="button"
                        className="transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 rounded-full"
                        onClick={increment}
                      >
                        {type === 'year' ? (
                          <div>
                            <span className="font-semibold text-xs text-gray-500 pl-2">+10</span>
                            <svg
                              className="h-6 w-6 text-gray-500 inline-flex"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M9 5l7 7-7 7"
                              />
                            </svg>
                          </div>
                        ) : (
                          <svg
                            className="h-6 w-6 text-gray-500 inline-flex"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M9 5l7 7-7 7"
                            />
                          </svg>
                        )}
                      </button>
                    </div>
                  </div>
                  {type === 'date' && (
                    <>
                      <div className="flex flex-wrap mb-3 -mx-1">
                        {DAYS.map((day, i) => (
                          <div key={i} style={{ width: '14.26%' }} className="px-1">
                            <div className="text-gray-600 font-medium text-center text-xs">
                              {day}
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="flex flex-wrap -mx-1">
                        {blankDays.map((_, i) => (
                          <div
                            key={i}
                            style={{ width: '14.26%' }}
                            className="text-center border p-1 border-transparent text-sm"
                          ></div>
                        ))}
                        {dayCount.map((d, i) => (
                          <div key={i} style={{ width: '14.26%' }} className="px-1 mb-1">
                            <div
                              onClick={setDateValue(d)}
                              className={`cursor-pointer text-center text-sm leading-none rounded-full leading-loose transition ease-in-out duration-100 ${
                                isToday(d)
                                  ? 'bg-teal-600 text-white'
                                  : 'text-gray-600 hover:bg-teal-100'
                              }`}
                            >
                              {d}
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                  {type === 'month' && (
                    <div className="flex flex-wrap -mx-1">
                      {Array(12)
                        .fill(null)
                        .map((_, i) => (
                          <div key={i} onClick={setMonthValue(i)} style={{ width: '25%' }}>
                            <div
                              className={`cursor-pointer capitalize p-5 font-semibold text-center text-sm rounded-lg hover:bg-gray-200 ${
                                isSelectedMonth(i)
                                  ? 'bg-teal-400 text-white hover:bg-teal-400'
                                  : 'text-gray-600 hover:bg-teal-100'
                              }`}
                            >
                              {format(
                                new Date(
                                  datepickerHeaderDate.getFullYear(),
                                  i,
                                  datepickerHeaderDate.getDate()
                                ),
                                'MMM',
                                { locale: ptBR }
                              )}
                            </div>
                          </div>
                        ))}
                    </div>
                  )}{' '}
                  {type === 'year' && (
                    <div className="flex flex-wrap -mx-1">
                      {Array(12)
                        .fill(null)
                        .map((_, i) => (
                          <div
                            key={i}
                            onClick={setYearValue(getPreviousYear(i))}
                            style={{ width: '25%' }}
                          >
                            <div
                              className={`cursor-pointer capitalize py-5 px-4 font-semibold text-center text-sm rounded-lg hover:bg-gray-200 text-gray-600 hover:bg-teal-100'
                              `}
                            >
                              {getPreviousYear(i)}
                            </div>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DatepickerForm;
