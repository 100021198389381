import { Disclosure } from '@headlessui/react';
import {
  DocumentTextIcon,
  FolderIcon,
  FolderOpenIcon,
  PencilIcon,
  PhotographIcon,
  TrashIcon
} from '@heroicons/react/solid';
import { FolderLock } from 'lucide-react';
import { Action } from '../../../../../../../components/Table';
import { useAuth } from '../../../../../../auth/contexts/AuthProvider';
import { Directory } from '../../../../../models/Directory';
import { Document } from '../../../../../models/Document';
import ChildDisclosure from './ChildDisclosure';
import DropdownDisclosure from './DropdownDisclosure';

type Props = {
  directory: Directory;
  actions: Action[];
  viewDocument: (document: Document) => void;
  onClickEditDocument: (documentId: string) => void;
  onClickDeleteDocument: (documentId: string) => void;
  onClickEditDirectory: (directoryId: number) => void;
  onClickAgroupDirectory: (directoryId: number) => void;
};

const DisclosureItem = ({
  directory,
  actions,
  onClickAgroupDirectory,
  onClickDeleteDocument,
  onClickEditDirectory,
  onClickEditDocument,
  viewDocument
}: Props) => {
  const { user } = useAuth();

  const handleDocumentIcon = (document: Document) => {
    const documentIcon = document.file.mimetype.split('/');
    if (documentIcon[0] === 'image') {
      return <PhotographIcon className="md:h-6 md:w-6 h-5 w-5 text-teal-600" />;
    } else {
      return <DocumentTextIcon className="md:h-6 md:w-6 h-5 w-5 text-teal-600" />;
    }
  };

  return (
    <Disclosure>
      {({ open }) => (
        <>
          <div className="flex items-center transition-all md:px-3 md:py-2 px-1 py-2">
            <Disclosure.Button
              className={`gap-2 text-left items-center cursor-pointer w-full flex`}
            >
              {directory.isActive ? (
                <>
                  {open ? (
                    <FolderOpenIcon className="md:h-8 md:w-8 h-6 w-6 text-teal-600" />
                  ) : (
                    <FolderIcon className="md:h-8 md:w-8 h-6 w-6 text-teal-600" />
                  )}
                </>
              ) : (
                <div className="relative">
                  <FolderLock className="mx-1 md:h-6 md:w-6 h-5 w-5 text-stone-600" />
                </div>
              )}
              <span className="font-medium text-gray-600 hover:font-semibold line-clamp-1">
                {directory.name}
              </span>
            </Disclosure.Button>
            {user?.isAdmin && (
              <DropdownDisclosure
                values={directory}
                menuItems={actions}
                className="flex text-center justify-center"
              />
            )}
          </div>
          <Disclosure.Panel>
            {directory.subDirectories &&
              directory.subDirectories?.length > 0 &&
              directory.subDirectories?.map((subDirectory, index) => (
                <div key={index}>
                  {user?.isAdmin && subDirectory ? (
                    <ChildDisclosure
                      subDirectory={subDirectory}
                      onClickDeleteDocument={onClickDeleteDocument}
                      onClickEditDocument={onClickEditDocument}
                      onClickEditDirectory={onClickEditDirectory}
                      onClickAgroupDirectory={onClickAgroupDirectory}
                      viewDocument={viewDocument}
                    />
                  ) : (
                    subDirectory.isActive && (
                      <ChildDisclosure
                        subDirectory={subDirectory}
                        onClickDeleteDocument={onClickDeleteDocument}
                        onClickEditDocument={onClickEditDocument}
                        onClickEditDirectory={onClickEditDirectory}
                        onClickAgroupDirectory={onClickAgroupDirectory}
                        viewDocument={viewDocument}
                      />
                    )
                  )}
                </div>
              ))}
            {directory.documents?.map((document, index) => (
              <div
                key={index}
                className="flex sm:bg-opacity-0 md:pl-4 pl-5 border-t border-white py-2 justify-between"
              >
                <div
                  className="flex gap-1 pt-1 border-stone-500 text-gray-600 font-medium cursor-pointer hover:font-semibold"
                  onClick={() => viewDocument(document)}
                >
                  {handleDocumentIcon(document)}
                  {document.title}
                </div>
                {user?.isAdmin && (
                  <div className="flex mr-4 mt-1.5 justify-between gap-2">
                    <TrashIcon
                      className="sm:w-5 sm:h-5 w-4 h-4 cursor-pointer hover:text-red-500 text-red-600"
                      onClick={() => onClickDeleteDocument(document.id)}
                    />
                    <PencilIcon
                      className="sm:w-5 sm:h-5 w-4 h-4 cursor-pointer text-teal-600 hover:text-teal-500"
                      onClick={() => onClickEditDocument(document.id)}
                    />
                  </div>
                )}
              </div>
            ))}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default DisclosureItem;
