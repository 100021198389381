import saveAs from 'file-saver';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import Pagination from '../../../../components/Paginate/Pagination';
import TitleCard from '../../../../components/TitleCard';
import {
  manageApiErrorMessages,
  manageApiErrorResponse
} from '../../../../helpers/handleApiErrorResponse';
import { Paginate } from '../../../_shared/types/api.types';
import { PaginateReportIncomeDto } from '../../dto/PaginateReportIncomeDto';
import { ReportIncome } from '../../models/ReportIncome';
import reportIncomeService from '../../service/reportIncome.service';
import UserReportIncomeTable from './components/ReportIncomeTable';
import ReportIncomeViewModal from './components/ReportIncomeViewModal';

const ListReportIncome = () => {
  const [paginateReportIncomes, setPaginateReportIncomes] = useState<Paginate<ReportIncome>>();
  const [paginationActive, setPaginationActive] = useState<PaginateReportIncomeDto>({});
  const [urlReportIncome, setUrlReportIncome] = useState('');
  const [isOpenModal, setIsOpenModal] = useState(false);

  const loadReportIncomes = async () => {
    const result = await reportIncomeService.paginateReportIncome({
      ...paginateReportIncomes,
      limit: 10
    });
    setPaginateReportIncomes(result);
  };

  const onChangePage = async (page: number) => {
    setPaginationActive((old) => ({ ...old, page }));
  };

  const getReportIncome = async (reportIncomeId: number) => {
    try {
      const response = await reportIncomeService.getReportIncome(reportIncomeId);
      return URL.createObjectURL(response);
    } catch (error) {
      handleErrors(error);
    }
  };

  const handleErrors = (resultError: unknown) => {
    const resultErrorResponse = manageApiErrorResponse(resultError);
    const resultErrors = manageApiErrorMessages(resultErrorResponse);
    resultErrors.forEach((resultError) => toast.error(resultError));
  };

  const handleClickReportIncomeView = async (reportIncomeId: number, isMobile?: boolean) => {
    const urlReportIncome = await getReportIncome(reportIncomeId);
    if (!urlReportIncome) return;

    if (isMobile) {
      window.open(urlReportIncome, 'blank');
    } else {
      setIsOpenModal(true);
      setUrlReportIncome(urlReportIncome);
    }
  };

  const handleClickReportIncomeDownload = async (reportIncome: ReportIncome) => {
    const urlReportIncome = await getReportIncome(reportIncome.id);
    if (!urlReportIncome) return;
    const referenceYear = reportIncome.fileReportIncome.referenceYear;
    saveAs(urlReportIncome, `Informe_Rendimento_${referenceYear}.pdf`);
  };

  const handleClickCloseModal = () => {
    setIsOpenModal(false);
  };

  useEffect(() => {
    loadReportIncomes();
  }, [paginationActive]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-wrap justify-between">
        <TitleCard text="Informe de Rendimento" />
      </div>
      <div className="flex flex-col mt-6 gap-4">
        <UserReportIncomeTable
          reportIncomes={paginateReportIncomes?.results}
          onClickView={handleClickReportIncomeView}
          onClickDownload={handleClickReportIncomeDownload}
        />
        <Pagination
          currentPageLength={paginateReportIncomes?.results.length}
          page={paginateReportIncomes?.currentPage}
          pageSize={paginateReportIncomes?.limit}
          onChangePage={onChangePage}
          totalItems={paginateReportIncomes?.totalItems}
        />
      </div>
      <ReportIncomeViewModal
        isOpen={isOpenModal}
        closeModal={handleClickCloseModal}
        urlReportIncome={urlReportIncome}
      />
    </div>
  );
};

export default ListReportIncome;
