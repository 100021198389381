import { Report } from '../infra/Report';
import { Result } from '../modules/_shared/types/api.types';
import { FileError } from './fileError';

export function getFieldErrorsInReport(reports: Report[]): any {
  const fieldErrors: any = {};

  if (reports) {
    for (const report of reports) {
      const keyValue = fieldErrors[report.name];
      if (!keyValue) {
        fieldErrors[report.name] = report.message;
      }
    }
  }

  return fieldErrors;
}

export function manageApiErrorResponse(errorResponse: any): Result<any> {
  console.error('for debug:', errorResponse);

  if (errorResponse instanceof FileError) {
    const fileError: Report = {
      name: 'server',
      message: errorResponse.message
    };

    return {
      data: null,
      success: false,
      errors: [fileError] as Report[],
      message: fileError.message
    };
  }

  if (errorResponse.response && errorResponse.response.data) {
    const { data: dataResponse, status } = errorResponse.response;
    const { data, errors, success, message } = dataResponse as Result<any>;
    const responseError = {
      data,
      message,
      success,
      errors,
      statusCode: status
    } as Result<any>;

    return responseError;
  }

  const genericError: Report = {
    name: 'server',
    message: 'Falha na comunicação com o servidor'
  };

  const responseError = {
    success: false,
    errors: [genericError] as Report[],
    message: genericError.message
  } as Result<any>;

  return responseError;
}

// TODO: Centralizar o tratamento de erro
export function manageApiErrorMessages(
  errorResponse: Result<any>,
  ...namesToIgnore: string[]
): string[] {
  const errorMessages: string[] = [];
  const errors = errorResponse.errors;
  for (const error of errors) {
    if (namesToIgnore.includes(error.name)) continue;
    errorMessages.push(error.message);
  }

  return errorMessages;
}

export function getErrorMessage(errorResponse: Result<any>): string {
  const errorMessage = errorResponse.message;
  return errorMessage;
}

export function getFieldErrors(errorResponse: any): Record<string, string> {
  const fieldErrors: Record<string, string> = {};

  const errors = errorResponse?.response?.data?.errors;

  if (errors) {
    for (const report of errors) {
      const keyValue = fieldErrors[report.name];
      if (!keyValue) {
        fieldErrors[report.name] = report.message;
      }
    }
  }

  return fieldErrors;
}

export const getErrorMessagesByName = (errorResponse: any, ...names: string[]) => {
  const reports: Report[] | undefined = errorResponse?.response?.data?.errors;

  const messages: string[] = [];
  if (reports) {
    for (const name of names) {
      const founded = reports.find((report) => report.name.includes(name));
      if (founded) {
        messages.push(founded.message);
      }
    }
  }

  return messages;
};
