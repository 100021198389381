import { ArrowLeftIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../../../components/Button';
import TitleCard from '../../../../components/TitleCard';
import { Result } from '../../../_shared/types/api.types';
import { Directory } from '../../models/Directory';
import directoryService from '../../service/directory.service';
import CreateDirectoryModal from './components/CreateDirectoryModal';
import CreateSubdirectoryModal from './components/CreateSubdirectoryModal';
import DirectoryTable from './components/DirectoryTable';

const ListDirectory = () => {
  const [openNewOrUpdateDirectoryModal, setOpenNewOrUpdateDirectoryModal] = useState(false);
  const [openNewSubdirectoryModal, setOpenNewSubdirectoryModal] = useState(false);
  const [itemActive, setItemActive] = useState<Directory>();
  const [directories, setDirectories] = useState<Result<Directory[]>>();
  const [parentId, setParentId] = useState<number>();
  const history = useHistory();

  const handleOpenNewOrUpdateDirectory = async (directoryId?: number) => {
    if (directoryId) {
      const response = await directoryService.getDirectory(directoryId);
      setItemActive(response.data);
    }
    setOpenNewOrUpdateDirectoryModal(true);
  };

  const handleOpenAgroupDirectory = async (directoryId: number) => {
    if (directoryId) {
      const response = await directoryService.getDirectory(directoryId);
      setItemActive(response.data);
      setParentId(response.data.id);
    }
    setOpenNewSubdirectoryModal(true);
  };

  const handleCloseNewDirectory = () => {
    setOpenNewOrUpdateDirectoryModal(false);
    loadDirectories();
  };

  const handleCloseSubdirectory = () => {
    setOpenNewSubdirectoryModal(false);
  };

  const loadDirectories = async () => {
    const result = await directoryService.getDirectories();
    setDirectories(result);
  };

  useEffect(() => {
    loadDirectories();
  }, []);

  return (
    <div className="flex flex-col">
      <div className="flex sm:flex-row flex-col justify-between">
        <TitleCard text="Diretório" />
        <div className="flex justify-end items-center sm:pr-6 gap-2">
          <button
            className="bg-gray-100 hover:bg-gray-200 rounded flex flex-row items-center text-center font-medium text-base cursor-pointer px-2 py-1"
            onClick={() => history.goBack()}
          >
            <ArrowLeftIcon className="w-3 h-3 mr-1" />
            <span className="font-medium text-base">Voltar</span>
          </button>
          <div className="items-center justify-end sm:px-1 py-3">
            <Button
              variant="primary"
              type="button"
              onClick={() => handleOpenNewOrUpdateDirectory()}
            >
              Novo Diretório
            </Button>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-4">
        <DirectoryTable
          directory={directories?.data}
          onClickEditDirectory={handleOpenNewOrUpdateDirectory}
          onClickAgroupDirectory={() => handleOpenAgroupDirectory}
        />
      </div>
      <CreateDirectoryModal
        modalTitle="Assunto"
        isOpen={openNewOrUpdateDirectoryModal}
        onClose={handleCloseNewDirectory}
        directory={itemActive}
        onConfirm={loadDirectories}
      />
      <CreateSubdirectoryModal
        modalTitle="Assunto"
        isOpen={openNewSubdirectoryModal}
        onClose={handleCloseSubdirectory}
        parentId={parentId}
        directory={itemActive}
        onConfirm={loadDirectories}
      />
    </div>
  );
};

export default ListDirectory;
