import { ArrowLeftIcon } from '@heroicons/react/solid';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useHistory } from 'react-router-dom';
import Button from '../../../../components/Button';
import InputForm from '../../../../components/FormComponents/InputForm';
import InputMaskForm from '../../../../components/FormComponents/InputMaskForm';
import PasswordStrength from '../../../../components/PasswordStrength/PasswordStrength';
import { cleanMask } from '../../../../helpers/cleanMask';
import {
  getFieldErrors,
  manageApiErrorMessages,
  manageApiErrorResponse
} from '../../../../helpers/handleApiErrorResponse';
import authService from '../../services/auth.service';

export const FirstAccess = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const [inputPasswordValue, setInputPasswordValue] = useState('');

  const onHandleSubmit = async (data: any) => {
    const clearedMask = cleanMask(data.cpf);
    try {
      await authService.firstAccess({ ...data, cpf: clearedMask });
      toast.success('Primeiro acesso realizado com sucesso');
      history.push('/login');
    } catch (error: any) {
      handleErrors(error);
    }
  };

  const handleErrors = (resultError: unknown) => {
    const fieldsErrors = getFieldErrors(resultError);
    formRef.current?.setErrors(fieldsErrors);
    const resultErrorResponse = manageApiErrorResponse(resultError);
    const resultErrors = manageApiErrorMessages(resultErrorResponse);
    resultErrors.forEach((resultError) => toast.error(resultError));
  };

  return (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <h2 className="w-96 text-3xl mb-4 font-semibold text-teal-600">
        <b>Primeiro acesso</b>
      </h2>
      <div className="bg-yellow-100 p-4 w-96 mb-4 rounded-md">
        <p className="text-yellow-800">
          <b>Informação importante:</b> Somente pessoas pré-cadastradas pelo RH poderam realizar o
          primeiro acesso.
        </p>
      </div>
      <div className=" p-4 w-96 rounded border border-teal-500 border-dashed">
        <Form ref={formRef} onSubmit={onHandleSubmit}>
          <InputMaskForm name="cpf" maskChar={null} inputMask="999.999.999-99" label="CPF" />
          <InputForm className="mb-4" name="email" type="email" label="E-mail" />

          <InputForm
            className="mb-4"
            name="password"
            type="password"
            label="Senha"
            onChange={(e) => setInputPasswordValue(e.target.value)}
          />
          <PasswordStrength inputPasswordValue={inputPasswordValue} />
          <InputForm
            className="mb-4"
            name="confirmPassword"
            type="password"
            label="Confirmar a senha"
          />
          <div className="w-full flex justify-between mt-6">
            <Link to={'/'} className="text-teal-500 self flex flex-row items-center">
              <ArrowLeftIcon className="w-4 h-4 mr-1" />
              <span>Voltar para o login</span>
            </Link>
            <Button type="submit" variant="primary">
              Acessar
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};
