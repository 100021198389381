import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import Button from '../../../../components/Button';
import ImagePreviewModal from '../../../../components/ImagePreviewModal';
import ConfirmModal from '../../../../components/ModalComponents/ConfirmModal';
import TitleCard from '../../../../components/TitleCard';
import {
  getErrorMessage,
  manageApiErrorResponse
} from '../../../../helpers/handleApiErrorResponse';
import Routes from '../../../_shared/constants/Routes.enum';
import { Result } from '../../../_shared/types/api.types';
import { useAuth } from '../../../auth/contexts/AuthProvider';
import { Directory } from '../../models/Directory';
import { Document, FileDocument } from '../../models/Document';
import directoryService from '../../service/directory.service';
import documentService from '../../service/document.service';
import CreateDirectoryModal from '../ListDirectory/components/CreateDirectoryModal';
import CreateSubdirectoryModal from '../ListDirectory/components/CreateSubdirectoryModal';
import DisclosurePanels from './components/DisclosurePanels';

const ListDocuments = () => {
  const [listDocumentsGroup, setListDocumentsGroup] = useState<Document[]>();
  const [directories, setDirectories] = useState<Result<Directory[]>>();
  const [openDeleteDocumentModal, setOpenDeleteDocumentModal] = useState(false);
  const [openEditDirectoryModal, setOpenEditDirectoryModal] = useState(false);
  const [openAgroupDirectoryModal, setOpenAgroupDirectoryModal] = useState(false);
  const [documentActive, setDocumentActive] = useState<Document>();
  const [itemActive, setItemActive] = useState<Directory>();
  const [parentId, setParentId] = useState<number>();
  const [isOpen, setIsOpen] = useState(false);
  const [image, setImage] = useState<FileDocument>();
  const { user } = useAuth();
  const history = useHistory();

  const loadDocuments = async () => {
    const result = await documentService.findAllDocument();
    setListDocumentsGroup(result.results);
  };

  const loadDirectories = async () => {
    const result = await directoryService.getDirectories();
    setDirectories(result);
  };

  const handleErrors = (resultError: unknown) => {
    const resultErrorResponse = manageApiErrorResponse(resultError);
    const error = getErrorMessage(resultErrorResponse);
    toast.error(error);
  };

  const handleClickDocumentView = async (document: Document) => {
    try {
      await documentService.getImageIfExists(document.file.completeName);
      setImage(document.file);
      setIsOpen(true);
    } catch (resultError) {
      toast.error('Documento não encontrado');
    }
  };

  const getImageUrl = (imageName: string) => {
    return documentService.getImageUrl(imageName);
  };

  const handleClickEditDocument = async (documentId: string) => {
    history.push(`/document/${documentId}`);
  };

  const handleClickDeleteDocument = async (documentId: string) => {
    if (documentId) {
      const response = await documentService.getDocument(documentId);
      setDocumentActive(response.data);
    }
    setOpenDeleteDocumentModal(true);
  };

  const handleConfirmDeleteDocument = async () => {
    if (documentActive) {
      try {
        const result = await documentService.deleteDocument(documentActive.id);
        toast.success(result.message);
        setOpenDeleteDocumentModal(false);
        loadDirectories();
      } catch (error) {
        handleErrors(error);
      }
    }
  };

  const handleOpenNewOrEditDirectory = async (directoryId?: number) => {
    if (directoryId) {
      const response = await directoryService.getDirectory(directoryId);
      setItemActive(response.data);
    }
    setOpenEditDirectoryModal(true);
  };

  const handleNewDirectory = () => {
    setItemActive(undefined);
    setOpenEditDirectoryModal(true);
  };

  const handleOpenAgroupDirectory = async (directoryId: number) => {
    if (directoryId) {
      const response = await directoryService.getDirectory(directoryId);
      setItemActive(response.data);
      setParentId(response.data.id);
    }
    setOpenAgroupDirectoryModal(true);
  };

  useEffect(() => {
    loadDirectories();
    loadDocuments();
  }, []);

  return (
    <div className="flex flex-col">
      <div className="flex flex-col sm:flex-row justify-between">
        <TitleCard text="Documentos" />
        {user?.isAdmin && (
          <div className="flex flex-col sm:flex-row sm:items-end py-5 gap-3">
            <Button variant="primary" type="button" onClick={() => handleNewDirectory()}>
              Novo Diretório
            </Button>
            <Button
              variant="primary"
              type="button"
              onClick={() => history.push(Routes.CREATE_DOCUMENT)}
            >
              Novo Documento
            </Button>
          </div>
        )}
      </div>
      <div className="mt-6">
        <DisclosurePanels
          groupedDirectories={directories?.data}
          viewDocument={handleClickDocumentView}
          onClickEditDocument={handleClickEditDocument}
          onClickDeleteDocument={handleClickDeleteDocument}
          onClickEditDirectory={handleOpenNewOrEditDirectory}
          onClickAgroupDirectory={handleOpenAgroupDirectory}
        />
      </div>

      <ConfirmModal
        modalText={`Deseja realmente excluir o documento ${documentActive?.title}?`}
        onClose={() => setOpenDeleteDocumentModal(false)}
        confirmButton={{
          buttonText: 'Confirmar',
          onClick: handleConfirmDeleteDocument
        }}
        cancelButton={{
          buttonText: 'Cancelar',
          onClick: () => setOpenDeleteDocumentModal(false)
        }}
        isOpen={openDeleteDocumentModal}
      />

      <ImagePreviewModal
        getUrlImage={getImageUrl}
        image={image}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />

      <CreateDirectoryModal
        modalTitle="Novo diretório"
        isOpen={openEditDirectoryModal}
        onClose={() => setOpenEditDirectoryModal(false)}
        directory={itemActive}
        onConfirm={loadDirectories}
      />

      <CreateSubdirectoryModal
        modalTitle="Novo Subdiretório"
        isOpen={openAgroupDirectoryModal}
        onClose={() => setOpenAgroupDirectoryModal(false)}
        parentId={parentId}
        directory={itemActive}
        onConfirm={loadDirectories}
      />
    </div>
  );
};

export default ListDocuments;
