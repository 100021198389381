import queryString from 'query-string';
import api from '../../_shared/services/api';
import { Paginate, Result } from '../../_shared/types/api.types';
import { CreateAggregatedHoleriteDto } from '../dtos/CreateAggregatedHoleriteDto';
import { PaginateAggregratedHoleriteDto } from '../dtos/PaginateAggregratedHoleriteDto';
import { ResultAggregatedHoleriteDto } from '../dtos/ResultAggregatedHoleriteDto';
import { AggregatedHolerite } from '../models/AggregatedHolerite';

class AggregatedHoleriteService {
  public async newAggregatedHolerite(
    aggregatedHolerite: CreateAggregatedHoleriteDto
  ): Promise<Result<ResultAggregatedHoleriteDto>> {
    const data = JSON.stringify(aggregatedHolerite);

    const formData = new FormData();
    formData.append('data', data);
    formData.append('files', aggregatedHolerite.txtFile[0]);

    const response = await api({
      method: 'POST',
      url: '/aggregated-holerites',
      data: formData,
      maxContentLength: Infinity,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    return response.data;
  }

  public async removeAggregatedHolerite(id: number): Promise<Result<void>> {
    const response = await api.delete(`/aggregated-holerites/${id}`);

    return response.data;
  }

  public async paginateAggregatedHolerite({
    ...paginateAggregatedHolerite
  }: PaginateAggregratedHoleriteDto): Promise<Paginate<AggregatedHolerite>> {
    const queryParams = queryString.stringify(paginateAggregatedHolerite);

    const response = await api.get(`/aggregated-holerites?${queryParams}`);

    return response.data.data;
  }
}

export default new AggregatedHoleriteService();
