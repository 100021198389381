import { DocumentTextIcon, PencilIcon } from '@heroicons/react/solid';
import Button from '../../../../../../components/Button';
import ListCard from '../../../../../../components/ListCard';
import Table from '../../../../../../components/Table';
import { translateBoolean } from '../../../../../_shared/helpers/FormatText';
import { ProtectedUserClone } from '../../../../models/User';

interface Props {
  employees?: ProtectedUserClone[];
  onClickEditEmployee: (userId: number) => void;
  onClickViewEmployeeHolerite: (userId: number) => void;
}

const EmployeeTable = ({ employees, onClickEditEmployee, onClickViewEmployeeHolerite }: Props) => {
  const translateRowContracted = (value: boolean) => {
    switch (value) {
      case true:
        return 'Contratado';
      case false:
        return 'Desligado';
      default:
        return '';
    }
  };

  return (
    <>
      <div className="sm:flex hidden">
        <Table
          columns={[
            { columnName: 'Nome', key: 'name' },
            {
              columnName: 'Administrador',
              key: 'isAdmin',
              transform: (value) => translateBoolean(value)
            },
            {
              columnName: 'Status',
              key: 'isContracted',
              transform: (value) => translateRowContracted(value)
            },
            {
              columnName: '',
              key: '',
              component: (value, itemActive) => (
                <div className="flex justify-end space-x-2 gap-1">
                  <DocumentTextIcon
                    className="w-5 cursor-pointer text-teal-600"
                    onClick={() => onClickViewEmployeeHolerite(itemActive.id)}
                  />
                  <PencilIcon
                    className="w-5 cursor-pointer text-teal-600"
                    onClick={() => onClickEditEmployee(itemActive.id)}
                  />
                </div>
              )
            }
          ]}
          values={employees}
        />
      </div>
      <div className="sm:hidden flex">
        <ListCard
          itemsLabel={[
            { label: 'Nome', key: 'name' },
            {
              label: 'Administrador',
              key: 'isAdmin',
              transform: (value) => translateBoolean(value)
            },
            {
              label: 'Status',
              key: 'isContracted',
              transform: (value) => translateRowContracted(value)
            },
            {
              label: '',
              key: '',
              component: (value, itemActive) => (
                <div className="flex flex-col gap-2 sm:hidden w-full mt-[-16px]">
                  <Button
                    styles="w-full"
                    variant="primary"
                    onClick={() => onClickViewEmployeeHolerite(itemActive.id)}
                  >
                    Visualizar
                  </Button>
                  <Button
                    styles="w-full"
                    variant="primary"
                    onClick={() => onClickEditEmployee(itemActive.id)}
                  >
                    Editar
                  </Button>
                </div>
              )
            }
          ]}
          values={employees}
        />
      </div>
    </>
  );
};

export default EmployeeTable;
