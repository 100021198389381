import { TrashIcon } from '@heroicons/react/solid';
import Button from '../../../../../../components/Button';
import ListCard from '../../../../../../components/ListCard';
import Table from '../../../../../../components/Table';
import { cnpjMask } from '../../../../../../helpers/cnpjMask';
import { convertDateToString } from '../../../../../../helpers/convertToDate';
import { AggregatedHolerite } from '../../../../models/AggregatedHolerite';

interface Props {
  aggregatedHolerite?: AggregatedHolerite[];
  onClickDeleteHolerites: (AggregateHoleriteId: any) => void;
}

const AggregatedHoleriteTable = ({ aggregatedHolerite, onClickDeleteHolerites }: Props) => {
  const validateDate = (value: any) => {
    return value != null ? new Date(value) : undefined;
  };

  return (
    <>
      <div className="sm:flex hidden">
        <Table
          columns={[
            {
              columnName: 'Data de Crédito',
              key: 'creditDate',
              transform: (value) => convertDateToString(validateDate(value))
            },
            {
              columnName: 'CNPJ',
              key: 'businessGroup.cnpj',
              transform: (value) => cnpjMask(value)
            },
            {
              columnName: '',
              key: '',
              component: (value, itemActive) => (
                <div className="flex justify-end space-x-2">
                  <TrashIcon
                    className="w-5 cursor-pointer text-red-600 hover:text-red-500"
                    onClick={() => onClickDeleteHolerites(itemActive.id)}
                  />
                </div>
              )
            }
          ]}
          values={aggregatedHolerite}
        />
      </div>
      <div className="flex sm:hidden">
        <ListCard
          itemsLabel={[
            {
              label: 'Data de Crédito',
              key: 'creditDate',
              transform: (value) => convertDateToString(validateDate(value))
            },
            {
              label: 'CNPJ',
              key: 'businessGroup.cnpj',
              transform: (value) => cnpjMask(value)
            },
            {
              label: '',
              key: '',
              component: (value, itemActive) => (
                <div className="flex flex-col gap-2 sm:hidden w-full mt-[-16px]">
                  <Button
                    styles="w-full"
                    variant="error"
                    onClick={() => onClickDeleteHolerites(itemActive.id)}
                  >
                    Excluir
                  </Button>
                </div>
              )
            }
          ]}
          values={aggregatedHolerite}
        />
      </div>
    </>
  );
};

export default AggregatedHoleriteTable;
