type Props = {
  passwordScore: number;
};

const PasswordProgressBar = ({ passwordScore }: Props) => {
  return (
    <div className="w-full flex flex-row gap-1.5 mt-1">
      <div className="w-1/3 flex flex-col items-center gap-1">
        <div
          className={`w-full h-3 rounded transition-colors ${
            passwordScore
              ? passwordScore <= 2
                ? 'bg-red-400'
                : passwordScore <= 4
                ? 'bg-yellow-400'
                : 'bg-green-500'
              : 'bg-gray-200'
          }`}
        />
        <span className="text-xs font-medium text-gray-500">
          {passwordScore == 0 ? '' : passwordScore <= 2 && 'Fraca'}
        </span>
      </div>
      <div className="w-1/3 flex flex-col items-center gap-1">
        <div
          className={`w-full h-3 rounded transition-colors ${
            passwordScore
              ? passwordScore <= 2
                ? 'bg-gray-200'
                : passwordScore <= 4
                ? 'bg-yellow-400'
                : 'bg-green-500'
              : 'bg-gray-200'
          }`}
        />
        <span className="text-xs font-medium text-gray-500">
          {passwordScore <= 2 ? '' : passwordScore <= 4 ? 'Média' : ''}
        </span>
      </div>
      <div className="w-1/3 flex flex-col items-center gap-1">
        <div
          className={`w-full h-3 rounded transition-colors ${
            passwordScore ? (passwordScore <= 4 ? 'bg-gray-200' : 'bg-green-500') : 'bg-gray-200'
          }`}
        />
        <span className="text-xs font-medium text-gray-500">
          {passwordScore ? (passwordScore <= 4 ? '' : 'Forte') : ''}
        </span>
      </div>
    </div>
  );
};

export default PasswordProgressBar;
