import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import MainLayout from '../components/MainLayout';
import { FirstAccess } from '../modules/auth/pages/FirstAccess/Index';
import ForgotPassword from '../modules/auth/pages/ForgotPassword';
import Login from '../modules/auth/pages/Login';
import Routes from '../modules/_shared/constants/Routes.enum';

const AuthRoutes = () => {
  return (
    <HashRouter>
      <MainLayout>
        <Switch>
          <Route path={Routes.LOGIN} exact component={Login} />
          <Route path={Routes.FIRST_ACCESS} exact component={FirstAccess} />
          <Route path={Routes.FORGOT_PASSWORD} exact component={ForgotPassword} />
          <Redirect path="/" to={Routes.LOGIN} />
        </Switch>
      </MainLayout>
    </HashRouter>
  );
};
export default AuthRoutes;
