import { useField } from '@unform/core';
import { useEffect, useRef, useState } from 'react';
import ReactInputMask from 'react-input-mask';
import { cleanMask } from '../../helpers/cleanMask';

interface Props {
  name: string;
  placeholder?: string;
  label?: string;
  labelStyle?: string;
  inputStyle?: string;
  inputMask: string;
  maskChar?: any;
}

type InputProps = JSX.IntrinsicElements['input'] & Props;

const InputMaskForm = ({
  placeholder,
  labelStyle,
  inputStyle,
  label,
  name,
  inputMask
}: InputProps) => {
  const inputRef = useRef<any>();
  const { fieldName, registerField, defaultValue, error, clearError } = useField(name);
  const [value, setValue] = useState('');

  const handleChange = (event: any) => {
    clearError();
    const newValue = cleanMask(event.target.value);

    setValue(newValue);
  };

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: (ref: any) => {
        return value;
      },
      setValue: (ref: any, value: any) => {
        setValue(value);
      }
    });
  }, [fieldName, registerField, value, setValue]);

  return (
    <div className="flex flex-col mt-2 text-sm">
      <label
        htmlFor={name}
        className={labelStyle || `py-1 font-medium  ${error ? ' text-red-500' : 'text-gray-500'}`}
      >
        {label}
      </label>
      <ReactInputMask
        id={name}
        name={fieldName}
        mask={inputMask}
        ref={inputRef}
        defaultValue={defaultValue}
        onChange={handleChange}
        placeholder={placeholder}
        alwaysShowMask={true}
        value={value}
        className={`${inputStyle} rounded border p-2 focus:outline-none
          ${
            error
              ? 'border-red-500 focus:border-red-500 focus:ring-red-500 '
              : 'text-gray-500 border-gray-200 focus:border-teal-600 focus:ring-teal-600 '
          }
        `}
      />
      {error && <span className="text-red-500 text-xs mt-1 ml-1">{error}</span>}
    </div>
  );
};

export default InputMaskForm;
