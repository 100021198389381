import { ArrowLeftIcon } from '@heroicons/react/solid';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as PasswordPhoto } from '../../../../assets/password_new.svg';
import Button from '../../../../components/Button';
import InputForm from '../../../../components/FormComponents/InputForm';
import InputMaskForm from '../../../../components/FormComponents/InputMaskForm';
import PasswordStrength from '../../../../components/PasswordStrength/PasswordStrength';
import { cleanMask } from '../../../../helpers/cleanMask';
import {
  getFieldErrors,
  manageApiErrorMessages,
  manageApiErrorResponse
} from '../../../../helpers/handleApiErrorResponse';
import Routes from '../../../_shared/constants/Routes.enum';
import { ForgotPasswordTexts, TextForm } from '../../constants/ForgotPasswordTexts';
import { getForgotPasswordTextsForm } from '../../helpers/getForgotPasswordTextsForm';
import authService from '../../services/auth.service';

const enum Step {
  INIT_REDEFINE_PASSWORD = 1,
  INFORMATION = 2,
  REDEFINE_PASSWORD = 3
}

const ForgotPassword = () => {
  const CURRENT_URL = window.location.href;
  const VALIDATION_PARAM = 'verifyCode';
  const formRef = useRef<FormHandles>(null);
  const [step, setStep] = useState<Step>(Step.INIT_REDEFINE_PASSWORD);
  const [textsForm, setTextsForm] = useState<TextForm>(ForgotPasswordTexts[0]);
  const [inputPasswordValue, setInputPasswordValue] = useState('');
  const [emailRecovered, setEmailRecovered] = useState('');
  const history = useHistory();

  const handleSubmit = async (data: any) => {
    switch (step) {
      case Step.INIT_REDEFINE_PASSWORD:
        await handleForgotPassword(data);
        break;
      case Step.REDEFINE_PASSWORD:
        await handleRedefinePassword(data);
        break;
      default:
        break;
    }
  };

  const handleForgotPassword = async (data: any) => {
    try {
      const result = await authService.forgotPassword({
        cpf: cleanMask(data.cpf),
        originPath: CURRENT_URL,
        validationParam: VALIDATION_PARAM
      });
      setEmailRecovered(result.data.emailToSend);
      handleToSecondStep();
    } catch (error) {
      handleErrors(error);
    }
  };

  const handleToSecondStep = () => {
    setStep(2);
  };

  const handleRedefinePassword = async (data: any) => {
    const splitUrl = CURRENT_URL.split('=');
    const lastIndexOfArray = splitUrl.length - 1;
    const verifyCode = splitUrl[lastIndexOfArray];
    try {
      await authService.redefinePassword({
        ...data,
        verifyCode
      });
      toast.success('Sua senha foi redefinida com sucesso');
      history.push(Routes.LOGIN);
    } catch (error) {
      handleErrors(error);
    }
  };

  const handleErrors = (resultError: unknown) => {
    const fieldsErrors = getFieldErrors(resultError);
    formRef.current?.setErrors(fieldsErrors);
    const resultErrorResponse = manageApiErrorResponse(resultError);
    const resultErrors = manageApiErrorMessages(resultErrorResponse);
    resultErrors.forEach((resultError) => toast.error(resultError));
  };

  function handleRequestOrRedefinePage() {
    if (CURRENT_URL.includes('?') && CURRENT_URL.includes('=')) {
      setStep(3);
    } else {
      setStep(1);
    }
  }

  const getEmailToRecover = () => {
    return emailRecovered ? <span className="font-bold"> {emailRecovered}</span> : 'cadastrado';
  };

  useEffect(() => {
    handleRequestOrRedefinePage();
  }, []);

  useEffect(() => {
    setTextsForm(getForgotPasswordTextsForm(step));
  }, [step]);

  return (
    <div className="flex flex-col items-center w-full py-8">
      <div className="flex items-start w-full">
        <h1 className="text-3xl font-semibold text-teal-600 mb-4">Redefinir senha</h1>
      </div>
      <div className="flex flex-col w-full md:flex-row gap-8 md:gap-2 lg:gap-16">
        <div className="lg:w-1/2 w-full md:max-w-xl items-center justify-center flex flex-col gap-10 lg:gap-14 mt-8">
          <p className="text-lg text-stone-700">
            Caso tenha esquecido a senha ou queira altera-lá, preencha os dados solicitados
            referente a sua conta e siga as instruções.
          </p>
          <PasswordPhoto className="w-52 h-auto sm:w-[320px] mx-auto" />
        </div>
        <div className="lg:w-1/2 w-full flex flex-col self-start p-4 rounded border border-teal-500 border-dashed">
          <div className="w-full flex flex-col">
            <h2 className="text-2xl mb-2 font-semibold">
              <b>{textsForm.title}</b>
            </h2>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <div className="mb-4 ">
                {step === Step.INIT_REDEFINE_PASSWORD && (
                  <>
                    <InputMaskForm
                      name="cpf"
                      maskChar={null}
                      inputMask="999.999.999-99"
                      label="CPF*"
                    />
                  </>
                )}
                {step == Step.INFORMATION && (
                  <>
                    <div className="p-3 my-2 rounded bg-yellow-100 text-yellow-800 flex flex-col gap-2">
                      <span>
                        Enviamos um link de recuperação de senha para o e-mail {getEmailToRecover()}
                        .
                      </span>
                      <span>
                        Caso não tenha acesso a este e-mail, por favor entre em contato com o RH
                        para alteração do e-mail cadastrado.
                      </span>
                    </div>
                  </>
                )}
                {step === Step.REDEFINE_PASSWORD && (
                  <>
                    <InputForm
                      type="password"
                      name="password"
                      label="Nova senha"
                      className="mb-4"
                      onChange={(e) => setInputPasswordValue(e.target.value)}
                    />
                    <PasswordStrength inputPasswordValue={inputPasswordValue} />
                    <div className="mb-4"></div>
                    <InputForm
                      type="password"
                      name="confirmPassword"
                      label="Confirmar nova senha*"
                    />
                  </>
                )}
              </div>
              <div className="w-full flex justify-between mt-6">
                <Link to={'/'} className="text-teal-600 self flex flex-row items-center">
                  <ArrowLeftIcon className="w-4 h-4 mr-1" />
                  <span>Voltar para o login</span>
                </Link>
                {step !== 2 && (
                  <Button variant="primary" type="submit">
                    {step === Step.INIT_REDEFINE_PASSWORD ? 'Continuar' : 'Redefinir senha'}
                  </Button>
                )}
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
